import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Utility } from '../logics/Utility';
import { Singleton } from '../logics/Singleton';
import { BattleType } from '../logics/Symbols';
import { User } from '../logics/User';
import firebase from '../firebase';
import { Dialogs } from './Dialogs';

import '../css/main.css';
import '../css/friend-match.css';

export function FriendMatch() {
    const [roomId, setRoomId] = useState<string>("");
    const [canSpectate, setCanSpectate] = useState<boolean>(false);

    useEffect(() => {
        // バトルビューから帰ってきた時はリロードを入れる
        if(Singleton.fromBattleView){
            Singleton.fromBattleView = false;
            window.location.reload();
        }

        // 観戦ありか無しか
        const spectate = Utility.getQueryParams("canSpectate", window.location.search);
        if(spectate && spectate === "true") {
            setCanSpectate(true);
        }

        // NOTE:一旦使い回しはやめる
        // let uid = Utility.getLocalStorage(Defines.LocalStorageKey.uid);
        // if(!uid){
        //     uid = Utility.getUUID();
        //     Utility.setLocalStorage(Defines.LocalStorageKey.uid, uid);
        // }
        
        setRoomId(Utility.getUUID());
    }, []);

    function getURL(): string {
        return `battle-view?room=${roomId}&type=${BattleType.Friend.toString()}&canSpectate=${canSpectate}&openExternalBrowser=1`;
    }
    
    function getURLForSpectator(): string {
        return `battle-view?room=${roomId}&type=${BattleType.Friend.toString()}&canSpectate=${canSpectate}&spectate=true&openExternalBrowser=1`;
    }
    
    const to = {
        pathname: "/battle-view",
        search: `?room=${roomId}&type=${BattleType.Friend.toString()}&canSpectate=${canSpectate}&from=friend-match&openExternalBrowser=1`,
    }

    const sendEvent =  () => {
        firebase.analytics().logEvent('click_friendmatch', {
            nickname: User.nickName,
        });
    }

    return (
        <div className="friend-match" style={{background: "url(/bg/background_2.png) no-repeat left top/cover"}}>
            <div className="title">フレンド対戦</div>
            <div className="sub-title">FRIEND MATCH</div>
            <button className="button-normal" onClick={() => shareURL(getURL())}><img alt='' src="button/URLwo-okuru.png" /></button>
            {canSpectate && <button className="button-normal" onClick={() => shareURL(getURLForSpectator(), true)}><img alt='' src="button/kansen_URL.png" /></button>}
            <Link className="button-normal" to={to} onClick={sendEvent}><img alt='' src="button/nyu-sitsu.png" /></Link>
            <Link className="button-back" to="/battle-select"><img alt='' src="button/modoru.png" /></Link>
        </div>
    )
}

async function shareURL(url: string, isForSpectator: boolean = false) {
    if (navigator.share) {
        navigator.share({
            url,
        })
            .then(() => {
                console.log('Successful share');
            })
            .catch((error) => {
                console.log('Error sharing', error);
            });
    } else {
        const copyURL = `${window.location.hostname}/${url}`;
        await new Promise((resolve) => {
            Dialogs.Normal.show(
                `下記URLを${
                    isForSpectator ? "観戦" : "対戦"
                }する友達に送信してください
                ${copyURL}`,
                "閉じる",
                "URLをコピーして閉じる",
                async () => {
                    try {
                        await navigator.clipboard.writeText(copyURL);
                    } catch (error) {
                        console.log("Error copying", error);
                    }
                    resolve(true);
                },
                () => resolve(true)
            );
        });
        Singleton.closeVariousDialog();
    }
}