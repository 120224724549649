import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import ScriptTag from './ScriptTag';
import { Utility } from '../logics/Utility';
import { Defines } from '../logics/Define'
import firebase from '../firebase';
import { RoomUser } from '../logics/Scheme';
import { BattleType } from '../logics/Symbols';
import { User } from '../logics/User';
import { Singleton } from '../logics/Singleton';
import { Dialogs } from './Dialogs';
import '../css/main.css';
import '../css/battle-view.css';

const skywayScript = "//cdn.webrtc.ecl.ntt.com/skyway-4.4.2.js";
// const PeerOption = {
//     videoBandwidth: 2000,
//     audioBandwidth: 10,
// };

interface DataChannel {
    aspect?: number;
    camOff?: boolean;
    type?: string;
}

interface RoomUsers {
    nickname?: string;
    pid?: string;
    updatedAt: AnalyserNode;
    lineIdHash: string;
}

declare global {
    interface Window {
        __SKYWAY_KEY__: string;
        peer: any;
    }
}

interface Props extends RouteComponentProps {
}
interface State {
    micOn: boolean;
    camOn: boolean;
    isCalling: boolean;
    endDialog?: boolean;
    isFaceCam: boolean;
    opponentCamOff: boolean;
    isClosing?: boolean;
    cantConnectDialog?: boolean;
    audioRestartDialog?: boolean;
    showUI: boolean;
    displayRemote: boolean;
    testLog: string;
    isShowCommentModal?: boolean;
    comment: string;
    availableBlock: boolean;
    isShowBlockModal: boolean;
    availableReport: boolean;
    isShowReportModal: boolean;
    reportType: Array<number>;
    report: string;
}

class BattleView2Class extends React.Component<Props, State> {
    private docId: string | null = null;
    private isFreeMatch: boolean = false;
    private isSpectator: boolean = false;
    private refLocalVideo: React.RefObject<HTMLVideoElement> = React.createRef();
    private refRemoteVideo: React.RefObject<HTMLVideoElement> = React.createRef();
    private localStream?: MediaStream;
    private videoDeviceId?: string;
    private audioDeviceId?: string;
    private showUIInterval?: number;
    private nickname: string | null = null;
    private updateInterval?: number;
    private room: any = null;
    private peerId1: string = "";
    private peerId2: string = "";
    private canSpectateOnFriendMatch: boolean = false;
    private waitInterval?: number;
    private waitingTime: number = 0;

    public constructor(props: Props) {
        super(props);
        this.state = {
            micOn: true,
            camOn: true,
            isCalling: true,
            isFaceCam: false,
            opponentCamOff: false,
            showUI: true,
            displayRemote: true,
            testLog: "",
            isShowCommentModal: false,
            comment: "",
            availableBlock: false,
            isShowBlockModal: false,
            availableReport: false,
            isShowReportModal: false,
            reportType: [],
            report: ""
        };

        window.__SKYWAY_KEY__ = Defines.Application.IsDebugMode ? "176f35a3-ccd9-4854-9975-1dbb5995cf76" : "4795b2fa-a934-4a66-a87b-55fc8f090a93";

        this.openMedia = this.openMedia.bind(this);
        this.onResizeView = this.onResizeView.bind(this);
        this.onCloseButton = this.onCloseButton.bind(this);
        this.openConnection = this.openConnection.bind(this);
        this.closeConnection = this.closeConnection.bind(this);
        this.showUI = this.showUI.bind(this);
        this.onResize = this.onResize.bind(this);
        this.onResizeViewPlayer = this.onResizeViewPlayer.bind(this);
        this.onResizeViewSpectator = this.onResizeViewSpectator.bind(this);
        this.onVisibilityChange = this.onVisibilityChange.bind(this);
        this.onReceiveData = this.onReceiveData.bind(this);
        this.reconnect = this.reconnect.bind(this);
        this.selectDevices = this.selectDevices.bind(this);

        // * フリーマッチではdocId、フレンドマッチではuidをroomIdとして使用する
        this.docId = Utility.getQueryParams("room", window.location.search);
        const type = Utility.getQueryParams("type", window.location.search);
        this.isFreeMatch = type === BattleType.Free.toString() ? true : false;
        this.isFreeMatch ? console.log("フリーマッチを始めます") : console.log("フレンドマッチを始めます")
        this.isSpectator = Utility.getQueryParams("spectate", window.location.search) !== null;
        // フレンドマッチは観戦可能と不可でルームの形式を切り替える
        const canSpectate = Utility.getQueryParams("canSpectate", window.location.search);
        if(canSpectate && canSpectate === "true") {
            this.canSpectateOnFriendMatch = true;
        }
    }

    public async componentDidMount() {
        window.addEventListener("resize", this.onResize);
        document.addEventListener("visibilitychange", this.onVisibilityChange);
        // 操作UIを表示する
        this.showUI();
        if (this.isFreeMatch) {
            const db = firebase.firestore();
            const docRef = db.collection('rooms').doc(this.docId!);

            // ニックネームを取得
            const doc = await docRef.get();
            const users = await doc.data()!.users.filter((u: any) => u.nickname as string !== User.nickName);
            this.nickname = users[0].nickname;

            const postRef = db.collection('rooms').doc(this.docId!);
            const postDoc = await postRef.get();
            if (postDoc.get('waiting') === 'auto_out') {
                Dialogs.Normal.show('一定時間内の入室が確認できなかったため、マッチングを取り消しました', "キャンセル", "OK",
                () => {
                    Singleton.closeVariousDialog()
                    this.onCloseButton()
                },
                () => {
                    Singleton.closeVariousDialog()
                    this.onCloseButton()
                });
            } else {
                // フリーマッチの場合は定期的に接続更新
                this.updateInterval = window.setInterval(() => {
                    // ルーム自体の最新時刻更新
                    // const refThis = this;
                    const db = firebase.firestore();
                    const docRef = db.collection('rooms').doc(this.docId!);
                    docRef.update({
                        updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
                        waiting: 'waiting'
                    })
                        .catch(function(error) {
                            console.error("Error update documents: ", error);
                        });
                }, 5 * 1000)
            }
        }
        // アスペクト比に合わせて画面をリサイズする
        this.onResizeView(true);
    }

    public async componentWillUnmount() {
        if (this.showUIInterval) {
            window.clearInterval(this.showUIInterval);
        }
        if (this.updateInterval) {
            window.clearInterval(this.updateInterval);
        }
        if (this.waitInterval) {
            window.clearInterval(this.waitInterval)
        }

        window.removeEventListener("resize", this.onResize);
        document.removeEventListener("visibilitychange", this.onVisibilityChange);

        // フリーマッチの場合は自分の情報をルームから削除する
        // 一旦いらない
        // if (this.isFreeMatch) {
        //     const refThis = this;
        //     const db = firebase.firestore();
        //     var roomRef = db.collection("rooms").doc(this.docId!);
        //     await roomRef.withConverter(roomConverter)
        //         .get()
        //         .then(function (querySnapshot) {
        //             const room = querySnapshot.data();
        //             if (room !== undefined) {
        //                 roomRef.withConverter(roomUserConverter).update({
        //                     users: room.users.filter(u => u.pid !== refThis.fromId),
        //                 })
        //                     .catch(function (error) {
        //                         console.error("Error update documents: ", error);
        //                     });
        //             }
        //         })
        //         .catch(function (error) {
        //             console.error("Error update documents: ", error);
        //         });
        // }

        await this.closeConnection();
        await Utility.sleep(1000);

        if(this.localStream){
            this.localStream = undefined;
        }

        if (this.refLocalVideo.current) {
            Utility.releaseVideoTag(this.refLocalVideo.current, false);
            this.refLocalVideo.current.pause();
            this.refLocalVideo.current.srcObject = null;
        }
        if (this.refRemoteVideo.current) {
            Utility.releaseVideoTag(this.refRemoteVideo.current, false);
            this.refRemoteVideo.current.pause();
            this.refRemoteVideo.current.srcObject = null;
        }
    }

    public componentDidCatch(e: Error, info: React.ErrorInfo) {
        // nop
        console.error(e);
    }

    public render() {
        let myVideoClassName = this.state.isFaceCam ? "face-video" : "normal-video";
        myVideoClassName += this.state.camOn ? "" : " hide";

        return <div className={"webrtc " + (this.state.showUI ? "webrtc-ui-on" : "webrtc-ui-off")} id="webrtc" onClick={() => this.showUI()}>
            <div className={"streams " + (this.isSpectator ? "spectator-view" : "player-view")}>
                {!this.isSpectator ?
                    // 対戦者UI
                    <React.Fragment>
                        {/* リモートストリーム表示 */}
                        <div className={this.state.displayRemote ? "remote-stream" : "hide"} id="remote-stream">
                            <video ref={this.refRemoteVideo} id="js-remote-stream" playsInline muted={false} autoPlay />

                            {this.state.isCalling && <div className="calling">
                                <p>相手の入室を待っています</p>
                            </div>}

                            {this.state.opponentCamOff && <div className="calling">
                                <p>相手のカメラがオフになりました</p>
                            </div>}
                        </div>

                        {/* ローカルストリーム表示 */}
                        <div className={this.state.displayRemote ? "hide" : "local-stream"} id="local-stream">
                            <video ref={this.refLocalVideo} id="js-local-stream" playsInline muted={true} autoPlay className={myVideoClassName} />

                            {this.state.camOn ? null : <div className="my-cam-off">
                                <p>カメラがオフになっています</p>
                            </div>}
                        </div>
                    </React.Fragment>
                    :
                    // 観戦者UI
                    <React.Fragment>
                        {/* 対戦者１のストリーム表示 */}
                        <div className="local-stream" id="local-stream">
                            <video ref={this.refLocalVideo} id="js-local-stream" playsInline muted={false} autoPlay />
                        </div>

                        {/* 対戦者２のストリーム表示 */}
                        <div className="remote-stream" id="remote-stream">
                            <video ref={this.refRemoteVideo} id="js-remote-stream" playsInline muted={false} autoPlay />
                        </div>

                        {this.state.isCalling && <div className="calling">
                            <p>プレイヤーの入室を待っています</p>
                        </div>}
                    </React.Fragment>
                }

                {/* 各種ボタン */}
                {!this.isSpectator ?
                    <div className="stream-buttons">
                        <button onClick={() => { this.switchDisplayStream() }}>
                            <img alt='' src="talk/eizou-kakunin.png" />
                        </button>
                        <button className="mic-off" id={this.state.micOn ? "on" : "off"} onClick={() => {
                            if (this.localStream) {
                                const audioTracks = this.localStream.getAudioTracks()[0];
                                const micOn = !this.state.micOn;

                                audioTracks.enabled = micOn;
                                const self = this;
                                self.setState({ micOn });
                            }
                        }}>
                            {this.state.micOn ? <img alt='' src="talk/mic-ON.png" /> : <img alt='' src="talk/mic-OFF.png" />}
                        </button>
                        <button className="cam-off" id={this.state.camOn ? "on" : "off"} onClick={() => {
                            if (this.localStream) {
                                const videoTracks = this.localStream.getVideoTracks()[0];
                                videoTracks.enabled = !videoTracks.enabled;
                                const camOn = videoTracks.enabled;
                                const self = this;
                                self.setState({ camOn });
                                if(this.room){
                                    this.room.send({ undefined, camOff: !camOn });
                                }
                            }
                        }}>
                            {this.state.camOn ? <img alt='' src="talk/camera-ON.png" /> : <img alt='' src="talk/camera-OFF.png" />}
                        </button>
                        <button className="close" onClick={() => { this.onCloseButton(); }}>
                            <img alt='' src="talk/taishutsu.png" />
                        </button>
                        <button className="cam-flip" onClick={async () => {
                            const audioDeviceIds: string[] = [];
                            const audioDeviceLabels: string[] = [];
                            const videoDeviceIds: string[] = [];
                            const videoDeviceLabels: string[] = [];
                            if (this.room && this.localStream) {
                                this.room.send({
                                    type: 'changeCamera'
                                })
                            }
                            if (this.localStream) {
                                this.localStream.getTracks().forEach((track) => { track.stop() });
                            }
                            const availableDevices = await navigator.mediaDevices.enumerateDevices();
                            availableDevices.forEach(_ => {
                                if (_.kind === "videoinput") {
                                    videoDeviceIds.push(_.deviceId);
                                    let label = _.label || `camera ${videoDeviceIds.length}`;
                                    videoDeviceLabels.push(label);
                                } else if (_.kind === "audioinput") {
                                    audioDeviceIds.push(_.deviceId);
                                    let label = _.label || `microphone ${audioDeviceIds.length}`;
                                    audioDeviceLabels.push(label);
                                }
                            });
                            await new Promise(resolve => {
                                // if (videoDeviceIds.length <= 1) {
                                //     this.videoDeviceId = videoDeviceIds[0];
                                //     this.audioDeviceId = audioDeviceIds[0];
                                //     resolve(true);
                                //     return;
                                // }
                                if (Utility.isPC()) {
                                    Dialogs.SelectDevice.show(videoDeviceLabels, audioDeviceLabels, videoDeviceIds, audioDeviceIds, (videoDevice, audioDevice) => {
                                        this.videoDeviceId = videoDeviceIds[videoDevice];
                                        this.audioDeviceId = audioDeviceIds[audioDevice];
                                        resolve(true);
                                    });
                                } else {
                                    Dialogs.SelectCamera.show(videoDeviceLabels, videoDeviceIds, (videoDevice) => {
                                        this.videoDeviceId = videoDeviceIds[videoDevice];
                                        resolve(true);
                                    });
                                }
                            });
                            
                            await this.openMedia(this.state.isFaceCam ? "user" : "environment");
                            if(this.room) {
                                this.room.replaceStream(this.localStream);
                                this.room.send({
                                    type: 'updateCamera'
                                })
                            }
                        }}>
                            <img alt='' src="talk/haguruma.png" />
                        </button>
                    </div>
                    // 観戦用ボタンUIなどを追加予定
                    : <div className="stream-buttons">
                        <button className="close" onClick={() => { this.onCloseButton(); }}>
                            <img alt='' src="talk/taishutsu.png" />
                        </button>
                    </div>}

                {/* 再接続ボタン */}
                <div className={"reconnect-button " + (this.state.showUI ? "webrtc-ui-on" : "webrtc-ui-off")}>
                    <button onClick={() => { this.reconnect(); }}>
                        再接続する（上手く繋がらない場合）
                    </button>
                </div>

                {/* 対戦相手のニックネーム表示 */}
                <div className="nickname">
                    {this.isFreeMatch && this.nickname &&
                        "対戦相手：" + this.nickname
                    }
                </div>
            </div>

            {/* <pre className="test-log" ref={this.refDebugLog}>{this.state.testLog}</pre> */}

            {/* skywayのスクリプトをCDNから取得 */}
            <ScriptTag src={skywayScript} onLoad={() => {
                setTimeout(() => {
                    this.openConnection(true);
                }, 500);
            }} />

            {/* アンケートモダール表示 */}
            {
                this.state.isShowCommentModal && (
                    <div className="battle-comment-modal">
                        <h4>今回の対戦はいかかでしたか？ご意見があれば自由にご記入ください</h4>
                        <textarea value={this.state.comment} onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) => {
                            this.setState({ comment: event.target.value })
                        }}></textarea>
                        <p className="block-check">
                            <input type="checkbox" checked={this.state.availableBlock} onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                this.setState({ availableBlock: event.target.checked })
                            }} />
                            <label>対戦相手に問題があった（アンケート回答後、対戦相手をブロックや通報することができます）</label>
                        </p>
                        <div className="button-group">
                            <button className="cancel-comment" onClick={() => { this.cancelComment(); }}>送信せずに閉じる</button>
                            <button className="send-comment" onClick={() => { this.sendComment(); }}>送信する</button>
                        </div>
                    </div>
                )
            }

            {/* ブロックモダール表示 */}
            {
                this.state.isShowBlockModal && (
                    <div className="battle-block-modal">
                        <h4>対戦相手をブロックしますか？<br/>（ブロックするとマッチング相手から除外されます）</h4>
                        <p className="block-check">
                            <input type="checkbox" checked={this.state.availableReport} onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                this.setState({ availableReport: event.target.checked })
                            }}/>
                            <label>対戦相手を運営に通報する</label>
                        </p>
                        <div className="button-group">
                            <button className="cancel-comment" onClick={() => { this.cancelBlock(); }}>ブロックせずに閉じる</button>
                            <button className="send-comment" onClick={() => { this.blockProc(); }}>ブロックする</button>
                        </div>
                    </div>
                )
            }

            {
                this.state.isShowReportModal && (
                    <div className="battle-comment-modal">
                        <h4 className="required">次の中から通報した理由を全て選んでください</h4>
                        <ul>
                            <li>
                                <label><input type="checkbox" checked={this.checkReportChecked(10)} onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                    this.toggleReportType(10)
                                }}/>対戦途中に切断された</label>
                            </li>
                            <li>
                                <label><input type="checkbox" checked={this.checkReportChecked(20)} onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                    this.toggleReportType(20)
                                }}/>不正行為をされた</label>
                            </li>
                            <li>
                                <label><input type="checkbox" checked={this.checkReportChecked(30)} onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                    this.toggleReportType(30)
                                }}/>暴言、誹謗中傷を行なわれた</label>
                            </li>
                            <li>
                                <label><input type="checkbox" checked={this.checkReportChecked(40)} onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                    this.toggleReportType(40)
                                }}/>住所や本名など個人情報を探られた</label>
                            </li>
                            <li>
                                <label><input type="checkbox" checked={this.checkReportChecked(50)} onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                    this.toggleReportType(50)
                                }}/>対戦相手に不快感を覚えた</label>
                            </li>
                            <li>
                                <label><input type="checkbox" checked={this.checkReportChecked(60)} onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                    this.toggleReportType(60)
                                }}/>その他</label>
                            </li>
                        </ul>
                        <h5>下のテキストボックスに状況を詳しく記載いただけます</h5>
                        <textarea value={this.state.report} onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) => {
                            this.setState({ report: event.target.value })
                        }}></textarea>
                        <div className="button-group">
                            <button className="cancel-comment" onClick={() => { this.cancelReport(); }}>通報せずに閉じる</button>
                            <button className="send-comment" onClick={() => { this.reportProc(); }}>通報する</button>
                        </div>
                    </div>
                )
            }

            {/* mask */}
            {
                (this.state.isShowCommentModal || this.state.isShowBlockModal || this.state.isShowReportModal) && (
                    <div className="mask"></div>
                )
            }
        </div>
    }

    public checkReportChecked(type: number) {
        return this.state.reportType.includes(type)
    }

    public toggleReportType(type: number) {
        if (this.state.reportType.includes(type)) {
            let reportType = this.state.reportType
            const index = reportType.indexOf(type)
            reportType.splice(index, 1)
            this.setState({ reportType })
        } else {
            let reportType = this.state.reportType
            reportType.push(type)
            this.setState({ reportType })
        }
    }

    public navigatePage() {    
        Singleton.fromBattleView = true;

        const from = Utility.getQueryParams("from", window.location.search);
        if (from) {
            if (from === "friend-match") {
                this.props.history.push(
                    `/${from}?canSpectate=${this.canSpectateOnFriendMatch}`
                );
            } else {
                this.props.history.push(`/${from}`);
            }
        } else {
            this.props.history.push("");
        }
    }

    public async sendComment() {
        if (this.state.comment.trim() === "" && !this.state.availableBlock) {
            // コメントもチェックもない場合は何もしない
            return
        }

        if (this.state.comment.trim() !== "") {
            // 意見を送信する
            const db = firebase.firestore();
            const authUserId = firebase.auth().currentUser
                ? firebase.auth().currentUser?.uid
                : null;
            const commentId = db.collection("comments").doc().id;
            await db.collection("comments").doc(commentId).set({
                commentId: commentId,
                comment: this.state.comment,
                userId: authUserId,
                roomId: this.docId,
                created: firebase.firestore.FieldValue.serverTimestamp(),
                updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
            });
        }

        if (this.state.availableBlock) {
            this.setState({ isShowBlockModal: true, isShowCommentModal: false })
        } else {
            this.setState({
                isShowCommentModal: false,
                isClosing: true
            })

            // await Utility.sleep(3500);
            this.navigatePage();
        }
    }

    public async cancelBlock() {
        this.setState({
            isShowBlockModal: false,
            isClosing: true
        })

        // await Utility.sleep(3500);
        this.navigatePage()
    }

    public async cancelReport() {
        this.setState({
            isShowReportModal: false,
            isClosing: true
        })

        // await Utility.sleep(3500);
        this.navigatePage();
    }

    public async blockProc() {
        const db = firebase.firestore();
        const authUserId = firebase.auth().currentUser?.uid;
        if (typeof authUserId !== "undefined" && authUserId !== "") {
            const roomRef = await db.collection("rooms").doc(this.docId!).get();
            const roomInfo = roomRef.data();
            const target = roomInfo?.users.find((item: RoomUsers) => {
                return (
                    item.lineIdHash !==
                    Utility.getHash(authUserId.replace("line:", ""))
                );
            });

            const blockRefs = await db
                .collection("blocks")
                .where("userId", "==", authUserId)
                .where("targetId", "==", target.lineIdHash)
                .get();
            // ブロックリスト重複チェック
            if (blockRefs.empty) {
                // 重複なし
                const blockId = db.collection("blocks").doc().id;
                await db
                    .collection("blocks")
                    .doc(blockId)
                    .set({
                        blockId: blockId,
                        userId: authUserId,
                        userIdHash: Utility.getHash(
                            authUserId.replace("line:", "")
                        ),
                        targetId: target.lineIdHash,
                        created:
                            firebase.firestore.FieldValue.serverTimestamp(),
                        updatedAt:
                            firebase.firestore.FieldValue.serverTimestamp(),
                    });
            }
        }

        if (this.state.availableReport) {
            this.setState({ isShowBlockModal: false, isShowReportModal: true });
        } else {
            this.setState({
                isShowReportModal: false,
                isClosing: true,
            });

            // await Utility.sleep(3500);
            this.navigatePage();
        }
    }

    public async reportProc() {
        if (this.state.reportType.length === 0) {
            // チェックがない場合は何もしない
            return;
        }

        const reportType = this.state.reportType;

        const db = firebase.firestore();
        const authUserId = firebase.auth().currentUser?.uid;
        if (typeof authUserId !== "undefined" && authUserId !== "") {
            const roomRef = await db.collection("rooms").doc(this.docId!).get();
            const roomInfo = roomRef.data();
            const target = roomInfo?.users.find((item: RoomUsers) => {
                return (
                    item.lineIdHash !==
                    Utility.getHash(authUserId.replace("line:", ""))
                );
            });
            const reportId = db.collection("reports").doc().id;
            await db
                .collection("reports")
                .doc(reportId)
                .set({
                    reportId: reportId,
                    roomId: this.docId,
                    userId: authUserId,
                    targetId: target.lineIdHash,
                    reportType: reportType.sort((a, b) => a - b),
                    content: this.state.report,
                    created: firebase.firestore.FieldValue.serverTimestamp(),
                    updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
                });
        }

        this.setState({
            isShowReportModal: false,
            isClosing: true,
        });

        // await Utility.sleep(3500);
        this.navigatePage();
    }

    public onResize() {
        return this.onResizeView(true);
    }

    public onResizeView(sendAspect: boolean) {
        if(this.isSpectator) {
            this.onResizeViewSpectator();
        } else{
            this.onResizeViewPlayer(sendAspect);
        }
    }

    public onResizeViewPlayer(sendAspect: boolean) {
        setTimeout(() => {
            const remoteDiv = document.getElementById("remote-stream");
            const localDiv = document.getElementById("local-stream");
            const remoteVideo = this.refRemoteVideo.current;
            const localVideo = this.refLocalVideo.current;
            const streams = document.getElementsByClassName("streams")![0] as HTMLElement;
            const target = document.getElementsByClassName("webrtc")![0] as HTMLElement;
            const webrtc = document.getElementById("webrtc");

            if (remoteDiv && localDiv && remoteVideo && localVideo && streams && target && webrtc) {
                const w = target.clientWidth;
                const h = window.innerHeight;
                const aspect = h / w;

                const localAspect = localVideo.videoHeight / localVideo.videoWidth;
                if (localAspect < aspect) {
                    localDiv.style.height = "100%";
                    localVideo.style.height = window.innerHeight + "px";

                    localDiv.style.width = "100%";
                    localVideo.style.width = window.innerHeight / aspect + "px";
                } else {
                    localDiv.style.height = "100%";
                    localVideo.style.height = target.clientWidth * aspect + "px";

                    localDiv.style.width = "100%";
                    localVideo.style.width = target.clientWidth + "px";
                }

                const remoteAspect = remoteVideo.videoHeight / remoteVideo.videoWidth;
                if (remoteAspect < aspect) {
                    remoteDiv.style.height = "100%";
                    remoteVideo.style.height = window.innerHeight + "px";

                    remoteDiv.style.width = "100%";
                    remoteVideo.style.width = window.innerHeight / aspect + "px";
                } else {
                    remoteDiv.style.height = "100%";
                    remoteVideo.style.height = target.clientWidth * aspect + "px";

                    remoteDiv.style.width = "100%";
                    remoteVideo.style.width = target.clientWidth + "px";
                }

                webrtc.style.height = window.innerHeight + "px";

                if (sendAspect && this.room) {
                    this.room.send({ aspect, camOff: !this.state.camOn });
                }
            }
        }, 200);
    }

    public onResizeViewSpectator() {
        setTimeout(() => {
            const remoteDiv = document.getElementById("remote-stream");
            const localDiv = document.getElementById("local-stream");
            const remoteVideo = this.refRemoteVideo.current;
            const localVideo = this.refLocalVideo.current;
            const streams = document.getElementsByClassName("streams")![0] as HTMLElement;
            const target = document.getElementsByClassName("webrtc")![0] as HTMLElement;
            const webrtc = document.getElementById("webrtc");

            if (remoteDiv && localDiv && remoteVideo && localVideo && streams && target && webrtc) {
                // const w = target.clientWidth;
                // const h = window.innerHeight;
                // const aspect = h / w;

                localDiv.style.height = target.clientWidth / 2 + "px";
                localDiv.style.width = "100%";
                remoteDiv.style.height = target.clientWidth / 2 + "px";
                remoteDiv.style.width = "100%";

                localVideo.style.height = "100%";
                localVideo.style.width = "100%";
                remoteVideo.style.height = "100%";
                remoteVideo.style.width = "100%";

                webrtc.style.height = window.innerHeight + "px";
            }
        }, 200);
    }

    public async onCloseButton() {
        await this.closeConnection();

        if (this.isFreeMatch) {
            this.setState({ isShowCommentModal: true });
        } else {
            // フリーマッチ以外はページ遷移
            this.navigatePage();
        }
    }

    public async cancelComment() {
        this.setState({ 
            isShowCommentModal: false,
            isClosing: true
        })
        // await Utility.sleep(3500);
        Singleton.fromBattleView = true;

        const from = Utility.getQueryParams("from", window.location.search);
        if (from) {
            if (from === "friend-match") {
                this.props.history.push(`/${from}?canSpectate=${this.canSpectateOnFriendMatch}`);
            } else {
                const db = firebase.firestore();
                const roomRef = await db
                    .collection("rooms")
                    .doc(this.docId!)
                    .get();
                if (roomRef.exists) {
                    const index = roomRef
                        .data()
                        ?.users.findIndex(
                            (user: RoomUser) => user.nickname === this.nickname
                        );
                    if (index !== -1) {
                        const noMatch = {
                            id: roomRef.data()?.users[index].lineIdHash,
                            matchedAt: Date.now(),
                        };

                        // 非マッチオブジェクト配列をローカルストレージから取得する
                        const noMatches = Utility.getLocalStorage(
                            Defines.LocalStorageKey.noMatches
                        );

                        // 非マッチオブジェクト配列をローカルストレージに設定する
                        if (noMatches) {
                            noMatches.push(noMatch);
                            Utility.setLocalStorage(
                                Defines.LocalStorageKey.noMatches,
                                noMatches
                            );
                        } else {
                            Utility.setLocalStorage(
                                Defines.LocalStorageKey.noMatches,
                                [noMatch]
                            );
                        }
                    }
                }

                this.props.history.push(`/${from}`);
            }
        } else {
            this.props.history.push("");
        }
    }

    public async openMedia(facingMode: "user" | "environment") {
        const isFaceCam = (facingMode === "user");
        if (this.localStream) {
            this.localStream.getTracks().forEach((track) => { track.stop() });
        }
        this.setState({ isFaceCam });

        const medias: MediaStreamConstraints = {
            audio: {
                echoCancellation: { ideal: true },
                deviceId: this.audioDeviceId ? { exact: [this.audioDeviceId] } : undefined
            },
            video: {
                facingMode: { exact: facingMode },
                frameRate: { ideal: 15 },
                aspectRatio: { ideal: 1.777777778 },
                // noiseSuppression: { ideal: true }
                deviceId: this.videoDeviceId ? { exact: [this.videoDeviceId] } : undefined
            }
        };

        const localVideo = this.refLocalVideo.current;
        if (localVideo) {
            const stream = localVideo.srcObject as MediaStream;
            if (stream) {
                localVideo.pause();
                stream.getTracks().forEach((track: any) => track.stop());
            }
            localVideo.srcObject = null;
            localVideo.load();
        }

        let localStream = await navigator.mediaDevices
            .getUserMedia(medias)
            .catch((err) => {
                console.error("OpenMedia CamError1:" + err);
            });

        if (!localStream) {
            const medias2: MediaStreamConstraints = {
                audio: {
                    echoCancellation: { ideal: true },
                    deviceId: this.audioDeviceId ? { exact: [this.audioDeviceId] } : undefined
                },
                video: {
                    facingMode,
                    frameRate: { ideal: 15 },
                    aspectRatio: { ideal: 1.777777778 },
                    // noiseSuppression: { ideal: true }
                    deviceId: this.videoDeviceId ? { exact: [this.videoDeviceId] } : undefined
                }
            };
            localStream = await navigator.mediaDevices
                .getUserMedia(medias2)
                .catch((err) => {
                    console.error("CamError2:" + err);
                });
        }

        if (!localStream) {
            const medias3: MediaStreamConstraints = {
                audio: true,
                video: {
                    facingMode: { exact: facingMode },
                }
            };
            localStream = await navigator.mediaDevices
                .getUserMedia(medias3)
                .catch((err) => {
                    console.error("CamError3:" + err);
                });
        }

        if (!localStream) {
            const medias4: MediaStreamConstraints = {
                audio: true,
                video: {
                    facingMode,
                }
            };
            localStream = await navigator.mediaDevices
                .getUserMedia(medias4)
                .catch((err) => {
                    console.error("CamError:" + err);
                    Singleton.showDialog("カメラの起動に失敗しました。\nカメラの使用を許可してください.");
                });
        }

        // if (!Utility.isiOS() && !Utility.isAndroid()) {
        //     alert("test4");
        //     if (isFacingModeChange || this.videoDeviceId === undefined) {
        //         // 一回カメラを取得しないとlabelが取れない環境がある
        //         await this.selectDevices();
        //         await this.openMedia("environment");
        //         return localStream;
        //     }
        // }

        // Render local stream
        if (localVideo && localStream) {
            this.localStream = localStream;

            localVideo.srcObject = localStream;
            localVideo.setAttribute("muted", "true");
            localVideo.muted = true;

            this.localStream.getAudioTracks().forEach(track => track.enabled = this.state.micOn);
            this.localStream.getVideoTracks().forEach(track => track.enabled = this.state.camOn);
            await localVideo.play().catch(console.error);
            this.onResizeView(true);
        }
        return localStream;
    }

    public onReceiveData(data: DataChannel) {
        console.log("DataChannel:" + JSON.stringify(data));

        const self = this;
        if (data.aspect) {
            this.onResizeView(false);
        }

        if (data.camOff !== undefined) {
            self.setState({ opponentCamOff: data.camOff });
        }

        if (data.type === 'changeCamera' && this.refRemoteVideo.current) {
            const remoteVideo = this.refRemoteVideo.current!;
            if (remoteVideo) {
                remoteVideo.style.display = 'none';
            }
        }
        if (data.type === 'updateCamera' && this.refRemoteVideo.current) {
            const remoteVideo = this.refRemoteVideo.current!;
            if (remoteVideo) {
                remoteVideo.style.display = 'block';
            }
        }
    }

    public async openConnection(call?: boolean) {
        if(!this.isSpectator) {
            await this.selectDevices();
            await this.openMedia(this.state.isFaceCam ? "user" : "environment");
        }

        // @ts-ignore
        const peer = (window.peer = new Peer({
            key: window.__SKYWAY_KEY__,
            debug: Defines.Application.IsDebugMode ? 1 : 1,
        }));

        const waitPeerOpen = () => new Promise<void>(resolve => {
            const id = window.setInterval(() => {
                if(peer.open) {
                    resolve();
                    window.clearInterval(id);
                }
            }, 1000)
        });
        await waitPeerOpen();

        if (!peer.open) {
            console.error("peerがopenされていません");
        }

        peer.on("error", (error: any) => {
            console.log(`${error.type}: ${error.message}`);
        });

        let room: any;
        if(this.isFreeMatch || !this.canSpectateOnFriendMatch) {
            console.log("mesh roomに入室します")
            room = peer.joinRoom(this.docId, {
                mode: "mesh",
                stream: this.localStream,
            });
        } else {
            // フレンドマッチは観戦の負荷を下げるためにsfuを使用する
            console.log("sfu roomに入室します")
            if(this.isSpectator) {
                // 観戦者は受信のみモードで入室する
                room = peer.joinRoom(this.docId, {
                    mode: "sfu",
                });
            } else {
                room = peer.joinRoom(this.docId, {
                    mode: "sfu",
                    stream: this.localStream,
                });
            }
        }
        this.room = room;

        room.once('open', () => {
            console.log('=== You joined ===');

            // マッチ後60秒間相手が入室しない場合にタイムアウトで自動退室
            if (this.isFreeMatch) {
                this.waitingTime = 0;
                this.waitInterval = window.setInterval(() => {
                    this.waitingTime++;
                    if (this.waitingTime > 60) {
                        this.waitingTime = 0;
                        if (this.waitInterval) window.clearInterval(this.waitInterval)

                        Dialogs.Normal.show('相手の入室が確認できなかったため、マッチングを取り消して退出します。よろしいですか？', "もう少し待つ", "退出する",
                        () => {
                            Singleton.closeVariousDialog()
                            const db = firebase.firestore();
                            const docRef = db.collection('rooms').doc(this.docId!);
                            docRef.update({
                                updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
                                waiting: 'auto_out'
                            })
                            .catch(function(error) {
                                console.error("Error update documents: ", error);
                            });
                            this.onCloseButton()
                        },
                        () => Singleton.closeVariousDialog());
                    }
                }, 1000);
            }
        });
        room.on('peerJoin', (peerId: any) => {
            console.log(`=== ${peerId} joined ===`);
            if (this.isFreeMatch && this.waitInterval) {
                this.waitingTime = 0;
                window.clearInterval(this.waitInterval)
            }
        });

        // Render remote stream for new peer join in the room
        room.on('stream', async (stream: any) => {
            console.log("onStreamが発火しました！");
            if (this.isFreeMatch && this.waitInterval) {
                this.waitingTime = 0;
                window.clearInterval(this.waitInterval)
            }

            // 観戦者はローカル→リモートの順で埋めていく
            if(this.isSpectator && !this.refLocalVideo.current!.srcObject) {
                this.peerId1 = stream.peerId;
                const localVideo = this.refLocalVideo.current!;
                localVideo.srcObject = stream;
                localVideo.playsInline = true;
                await localVideo.play().catch(console.error);
            } else {
                this.peerId2 = stream.peerId;
                const remoteVideo = this.refRemoteVideo.current!;
                remoteVideo.srcObject = stream;
                remoteVideo.playsInline = true;
                await remoteVideo.play().catch(console.error);
            }

            if(this.refLocalVideo.current!.srcObject !== null && this.refRemoteVideo.current!.srcObject !== null) {
                this.setState({ isCalling: false });
            }
        });

        room.on('data', ({ data, src }: any) => {
            // Show a message sent to the room and who sent
            console.log(`${src}: ${data}`);
            this.onReceiveData(data);
        });

        // for closing room members
        room.on('peerLeave', (peerId: any) => {
            if(this.isSpectator) {
                if(peerId === this.peerId1){
                    const localVideo = this.refLocalVideo.current!;
                    (localVideo.srcObject as MediaStream).getTracks().forEach(track => track.stop());
                    localVideo.srcObject = null;
                    this.setState({ isCalling: true });
                } else if (peerId === this.peerId2) {
                    const remoteVideo = this.refRemoteVideo.current!;
                    (remoteVideo.srcObject as MediaStream).getTracks().forEach(track => track.stop());
                    remoteVideo.srcObject = null;
                    this.setState({ isCalling: true });
                }
            } else {
                if(peerId === this.peerId2) {
                    const remoteVideo = this.refRemoteVideo.current!;
                    (remoteVideo.srcObject as MediaStream).getTracks().forEach(track => track.stop());
                    remoteVideo.srcObject = null;
                    this.setState({ isCalling: true });
                }
            }
            this.setState({ opponentCamOff: false });
            console.log(`=== ${peerId} left ===`);
        });

        // for closing myself
        room.once('close', () => {
            console.log('== You left ===');
        });
    }

    public async closeConnection() {
        if(this.localStream){
            const tracks = this.localStream.getTracks();
            tracks.forEach(t => t.stop());
        }

        if (this.room) {
            await this.room.close();
            this.room = null;
        }

        const peer = window.peer;
        if (peer) {
            peer.disconnect();
            peer.destroy();
        }
    }

    public showUI() {
        if (this.showUIInterval) {
            window.clearInterval(this.showUIInterval);
        }
        this.setState({ showUI: true });
        this.showUIInterval = window.setTimeout(() => {
            this.showUIInterval = undefined;
            this.setState({ showUI: false });
        }, 5000);
    }

    public switchDisplayStream() {
        this.setState({ displayRemote: !this.state.displayRemote });
    }

    public onVisibilityChange() {
        // if (document.visibilityState === "visible") {
        //     // window.location.reload();
        // } else {
        //     this.closeConnection();
        //     this.onCloseButton();
        // }
    }

    public async reconnect() {
        this.setState({ isClosing: true });
        await this.closeConnection();
        // ページ更新で再接続
        window.location.reload();
    }

    public async selectDevices() {
        const audioDeviceIds: string[] = [];
        const audioDeviceLabels: string[] = [];
        const videoDeviceIds: string[] = [];
        const videoDeviceLabels: string[] = [];
        if (!Utility.isPC()) {
            let stream = await navigator.mediaDevices.getUserMedia({ audio: true, video: true})
            if (stream) {
                stream.getTracks().forEach((track) => { track.stop() });
            }
        }
        const availableDevices = await navigator.mediaDevices.enumerateDevices();
        availableDevices.forEach(_ => {
            if (_.kind === "videoinput") {
                videoDeviceIds.push(_.deviceId);
                let label = _.label || `camera ${videoDeviceIds.length}`;
                videoDeviceLabels.push(label);
            } else if (_.kind === "audioinput") {
                audioDeviceIds.push(_.deviceId);
                let label = _.label || `microphone ${audioDeviceIds.length}`;
                audioDeviceLabels.push(label);
            }
        });

        await new Promise(resolve => {
            // if (videoDeviceIds.length <= 1 && audioDeviceIds.length <= 1) {
            //     this.videoDeviceId = videoDeviceIds[0];
            //     this.audioDeviceId = audioDeviceIds[0];
            //     resolve(true);
            //     return;
            // }
            if (Utility.isPC()) {
                Dialogs.SelectDevice.show(videoDeviceLabels, audioDeviceLabels, videoDeviceIds, audioDeviceIds, (videoDevice, audioDevice) => {
                    this.videoDeviceId = videoDeviceIds[videoDevice];
                    this.audioDeviceId = audioDeviceIds[audioDevice];
                    resolve(true);
                });
            } else {
                Dialogs.SelectCamera.show(videoDeviceLabels, videoDeviceIds, (videoDevice) => {
                    this.videoDeviceId = videoDeviceIds[videoDevice];
                    resolve(true);
                });
            }
        });
    }
}

export const BattleView2 = withRouter(BattleView2Class);
