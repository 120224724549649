import React from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import firebase from '../firebase';
import { Singleton } from '../logics/Singleton';
import { Card } from '../logics/Symbols';
import { User } from '../logics/User';
import { getActiveEvents } from './EventSelect';
import { Dialogs } from './Dialogs';
import '../css/main.css';
import '../css/free-match.css';

interface Props extends RouteComponentProps {
}
export class FreeMatch extends React.Component<Props, {}> {
	constructor(props: Props) {
		super(props);

		this.onClickCard = this.onClickCard.bind(this);
	}

	public componentDidMount() {
		// バトルビューから帰ってきた時はリロードを入れる
		if (Singleton.fromBattleView) {
			Singleton.fromBattleView = false;
			window.location.reload();
		}
	}

	public render() {
		return (
			<div className="free-match" style={{ background: "url(/bg/background3.png) no-repeat left top/cover" }}>
				<div className="title">フリー対戦</div>
				<div className="sub-title">FREE MATCH</div>
				<img alt='' className="select-banner" src="free_match/free-match-banner1.png" />
				<ul className="card-list">
					<li><img alt='' src="free_match/free-match-YUGIO-OCG.png" onClick={() => this.onClickCard(Card.Yugioh_OCG)} /></li>
					<li><img alt='' src="free_match/YUGIO-RUSHDUEL.png" onClick={() => this.onClickCard(Card.Yugioh_RushDuel)} /></li>
					<li><img alt='' src="free_match/free-match-POKEMON.png" onClick={() => this.onClickCard(Card.Pokemon)} /></li>
					<li><img alt='' src="free_match/free-match-DUELMASTERS.png" onClick={() => this.onClickCard(Card.DuelMasters)} /></li>
					<li><img alt='' src="free_match/free-match-MAGIC-the-GATHERING.png" onClick={() => this.onClickCard(Card.MTG)} /></li>
					<li><img alt='' src="free_match/digimon_cardgame.png" onClick={() => this.onClickCard(Card.Digimon)} /></li>
					<li><img alt='' src="free_match/Card-fight-VANGUARD.png" onClick={() => this.onClickCard(Card.Vanguard)} /></li>
					<li><img alt='' src="free_match/Weiβ-Schwarz.png" onClick={() => this.onClickCard(Card.WS)} /></li>
					<li><img alt='' src="free_match/Shadowverse_EVOLVE.png" onClick={() => this.onClickCard(Card.Shadowverse_EVOLVE)} /></li>
					<li><img alt='' src="free_match/free-match-ONE-PIECE.png" onClick={() => this.onClickCard(Card.OnePiece)} /></li>
					<li><img alt='' src="free_match/free-match-UNIONARENA.png" onClick={() => this.onClickCard(Card.UnionArena)} /></li>
					<li><img alt='' src="free_match/Dragon_ball.png" onClick={() => this.onClickCard(Card.DragonBall)} /></li>
				</ul>
				<Link to="/battle-select"><img alt='' className="button-back"  src="button/modoru.png" /></Link>
			</div>
		)
	}

	private async onClickCard(card: Card) {
		// イベント送信
		firebase.analytics().logEvent('click_freematch_' + Singleton.getCardType(card), {
			nickname: User.nickName,
		});
		switch (card) {
			case Card.Yugioh_OCG:
			case Card.Digimon:
			case Card.Yugioh_RushDuel:
			case Card.Shadowverse_EVOLVE:
			case Card.OnePiece:
			case Card.UnionArena:
			case Card.DragonBall:
					// イベント情報取得
				const events = await getActiveEvents();
				const result = events.filter((event) => {
					if (card === event.cardType) {
						return true;
					} else {
						return false;
					}
				})

				let isBattleStart = true;
				if (result.length === 1) {
					isBattleStart = await new Promise(resolve => {
						Dialogs.Normal.show(result[0].attention, "キャンセル", "対戦開始",
							() => resolve(true), () => resolve(false));
					});
					Singleton.closeVariousDialog();
				}
				if (isBattleStart) {
					this.props.history.push("/battle-wait?card=" + card + "&from=free-match");
				}
				break;

			case Card.Pokemon:
			case Card.DuelMasters:
			case Card.MTG:
			case Card.Vanguard:
			case Card.WS:
				// ポケカ、デュエマ、MtGはフォーマット選択に遷移
				this.props.history.push("/format-select?card=" + card);
				break;
			default:
				console.error("指定したカードタイプは存在しません", card);
		}

	}
}