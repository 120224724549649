export const Defines = {
    Application: {
        Version: "0.0.12",
        BuildNumber: "4",
        IsDebugMode: false,
        IsMaintainanceMode: false,
    },
    CheckUpdatedAtSeconds: 60,
    SelfAddress: {
        debug: "https://toyger-rimotaku-dev.web.app",
        product: "https://remotaku.com",
    },
    OfficialAccount: {
        debugId: "@550helpb",
        productId: "@364smqib",
    },
    LocalStorageKey : {
        uid: "uid",
        nickname: "nickname",
        noMatches: "noMatches",
	}
}