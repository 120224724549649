import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import firebase from '../firebase';
import { Singleton } from '../logics/Singleton';
import { Card, Event } from '../logics/Symbols';
import { User } from '../logics/User';
import { Dialogs } from './Dialogs';
import '../css/main.css';
import '../css/event-select.css';
import '../css/dialog.css';

export type EventData = {
	eventType: Event,
	cardType: Card;
	date: string;
	time: string;
	detail: string;
	attention: string;
};

export const getActiveEvents = async () => {
	let events: EventData[] = [];

	try {
		const db = firebase.firestore();
		const querySnapshot = await db
			.collection("events")
			.orderBy("cardType", "asc")
			.get();

		if (querySnapshot.size > 0) {
			const now = new Date().getTime();
			querySnapshot.forEach((eventSnap) => {
				const startAt = eventSnap.data().startAt.toDate();
				const endAt = eventSnap.data().endAt.toDate();
				if (startAt.getTime() <= now && now < endAt.getTime()) {
					// 1日またぎ判定
					const diffDay = Math.floor(
						(new Date(
							`${endAt.getFullYear()}/${
								endAt.getMonth() + 1
							}/${endAt.getDate()}`
						).getTime() -
							new Date(
								`${startAt.getFullYear()}/${
									startAt.getMonth() + 1
								}/${startAt.getDate()}`
							).getTime()) /
							(1000 * 60 * 60 * 24)
					);

					const endHour =
						diffDay === 1
							? ("0" + (endAt.getHours() + 24)).slice(-2)
							: ("0" + endAt.getHours()).slice(-2);

					const date = `${startAt.getFullYear()} ${(
						"0" +
						(startAt.getMonth() + 1)
					).slice(-2)}/${("0" + startAt.getDate()).slice(-2)}`;
					const time = `${("0" + startAt.getHours()).slice(-2)}:${(
						"0" + startAt.getMinutes()
					).slice(-2)}〜${endHour}:${(
						"0" + endAt.getMinutes()
					).slice(-2)}`;

					events.push({
						eventType: eventSnap.data().eventType,
						cardType: eventSnap.data().cardType,
						date,
						time,
						detail: eventSnap.data().detail.replaceAll("\\n", "\n"),
						attention: eventSnap.data().attention.replaceAll("\\n", "\n"),
					});
				}
			});
		}
	} catch (error) {
		console.log(error);

		return [];
	}

	return events;
};

const getEventImage = (eventType: Event, cardType: Card): string => {
	if (eventType === Event.RemoteKingdom) {
		switch (cardType) {
			case Card.Yugioh_OCG:
				return "RemoteKingdom_YUGIO-OCG.png";
			case Card.Pokemon_Standard:
				return "RemoteKingdom_POKEMON.png";
			case Card.DuelMasters_Original:
				return "RemoteKingdom_DUELMASTERS.png";
			case Card.OnePiece:
				return "RemoteKingdom_ONEPIECE.png";
			case Card.DragonBall:
				return "RemoteKingdom_DragonBall.png";
			default:
				console.error("指定したカードタイプが存在しません", cardType);
				return "";
		}
	} else {
		console.error("指定したイベントタイプは存在しません", eventType);
		return "";
	}
};


export const EventSelect = () => {
	const history = useHistory();

	const [events, setEvents] = useState<EventData[]>([]);

	const onClickEvent = async (event: Card, detail: string) => {
		const isBattleStart = await new Promise(resolve => {
			Dialogs.Normal.show(detail, "キャンセル", "対戦開始",
				() => resolve(true), () => resolve(false));
		});
		Singleton.closeVariousDialog();

		if (isBattleStart) {
			// イベント送信
			let card = Singleton.getCardType(event);
			if (card === "") {
				card = Singleton.getCardFormat(event);
			}
			firebase.analytics().logEvent(`click_event_${card}`, {
				nickname: User.nickName,
			});

			// マッチング待ちに遷移
			history.push(`/battle-wait?card=${event}&from=event-select`);
		}
	}

	useEffect(() => {
		// バトルビューから帰ってきた時はリロードを入れる
		if (Singleton.fromBattleView) {
			Singleton.fromBattleView = false;
			window.location.reload();
		}

		// イベント情報取得
		getActiveEvents()
			.then((events) => setEvents(events))
			.catch((error) => console.log(error));
	}, []);

	return (
		<div className="event-select" style={{ background: "url(/bg/background3.png) no-repeat left top/cover" }}>
			<div className="title">イベント</div>
			<div className="sub-title">EVENT</div>
			<div className="description">イベントボタンをタップするとルールなどの詳細を見ることができます</div>
			<img alt='' className="select-banner" src="event/event_banner.png" />
			<ul className="event-list">
				{events.length > 0
					?
					events.map((event, index) => (
						<li key={index}>
							<p className="session">開催中</p>
							<img
								alt=''
								className="event"
								src={`event/${getEventImage(event.eventType,event.cardType)}`}
								onClick={() => onClickEvent(event.cardType, event.detail)} />
							<p className="period">{event.date}<br />{event.time}</p>
						</li>
					))
					:
					<div className="description">現在開催中のイベントはありません</div>
				}
			</ul>
			<Link to="/battle-select"><img alt='' className="button-back" src="button/modoru.png" /></Link>
		</div>
	)
}