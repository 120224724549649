import * as React from 'react';

export interface ScriptTagProp {
    src: string,
    async?: boolean,
    onLoad?: () => void,
};
// https://qiita.com/m3816/items/36e5ce2351f3093f2402
class ScriptTag extends React.Component<ScriptTagProp, {}> {
    private divRef: React.RefObject<HTMLDivElement> = React.createRef();

    public constructor(props: ScriptTagProp) {
        super(props);
    }
    public componentDidMount() {
        this.initScript();
    }
    public initScript(){
        const { src, async, onLoad } = this.props;
        const script = document.createElement("script");

        script.src = src;
        script.async = async || false;
        script.onload = onLoad ? onLoad : null;

        const el = this.divRef.current;
        if (el) {
            el.replaceWith(script)
        }
    }

    public render() {
        return <div ref={this.divRef}/>
    }
}

export default ScriptTag