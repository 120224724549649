import React from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import firebase from '../firebase';
import { Singleton } from '../logics/Singleton';
import { Card } from '../logics/Symbols';
import { User } from '../logics/User';
import { getActiveEvents } from './EventSelect';
import { Dialogs } from './Dialogs';
import '../css/main.css';
import '../css/format-select.css';
import { Utility } from '../logics/Utility';

interface Props extends RouteComponentProps {
}
export class FormatSelect extends React.Component<Props, {}> {
	private cardType: Card | null = null;
	constructor(props: Props) {
		super(props);
		this.onClickFormat = this.onClickFormat.bind(this);
	}

	public componentDidMount() {
		// バトルビューから帰ってきた時はリロードを入れる
		if (Singleton.fromBattleView) {
			Singleton.fromBattleView = false;
			window.location.reload();
		}
	}


	public render() {
		const cardType = Utility.getQueryParams("card", window.location.search);
		if (!cardType) {
			// カードタイプが無い場合はカード選択に戻す
			this.props.history.replace("free-match");
		} else {
			this.cardType = parseInt(cardType);
		}
		return (
			<div className="format-select" style={{ background: "url(/bg/background3.png) no-repeat left top/cover" }}>
				<div className="title">フリー対戦</div>
				<div className="sub-title">FREE MATCH</div>
				{this.formatBanner()}
				{this.formatList()}
				<Link  to="/free-match"><img alt='' className="button-back" src="button/modoru.png" /></Link>
			</div>
		)
	}

	private formatBanner() {
		switch (this.cardType) {
			case Card.Pokemon:
			case Card.Vanguard:
				return <img alt='' className="select-banner" src="regulation/regulation.png" />
			case Card.WS:
				return <img alt='' className="select-banner" src="free_match/kochiku_rule_banner.png" />
			case Card.DuelMasters:
			case Card.MTG:
				return <img alt='' className="select-banner" src="regulation/format.png" />
		}
	}

	private formatList() {
		switch (this.cardType) {
			case Card.Pokemon:
				return (
					<ul className="format-list">
						<li><img alt='' src="regulation/standard.png" onClick={() => this.onClickFormat(Card.Pokemon_Standard)} /></li>
						<li><img alt='' src="regulation/extra.png" onClick={() => this.onClickFormat(Card.Pokemon_Extra)} /></li>
					</ul>
				);
			case Card.DuelMasters:
				return (
					<ul className="format-list">
						<li><img alt='' src="regulation/advance.png" onClick={() => this.onClickFormat(Card.DuelMasters_Advance)} /></li>
						<li><img alt='' src="regulation/original.png" onClick={() => this.onClickFormat(Card.DuelMasters_Original)} /></li>
					</ul>
				);
			case Card.MTG:
				return (
					<ul className="format-list">
						<li><img alt='' src="regulation/standard.png" onClick={() => this.onClickFormat(Card.MTG_Standard)} /></li>
						<li><img alt='' src="regulation/pioneer.png" onClick={() => this.onClickFormat(Card.MTG_Pioneer)} /></li>
						<li><img alt='' src="regulation/modern.png" onClick={() => this.onClickFormat(Card.MTG_Modern)} /></li>
						<li><img alt='' src="regulation/legacy.png" onClick={() => this.onClickFormat(Card.MTG_Legacy)} /></li>
					</ul>
				);
			case Card.Vanguard:
				return (
					<ul className="format-list">
						<li><img alt='' src="regulation/standard.png" onClick={() => this.onClickFormat(Card.Vanguard_Standard)} /></li>
						<li><img alt='' src="free_match/P_standard.png" onClick={() => this.onClickFormat(Card.Vanguard_VStandard)} /></li>
						<li><img alt='' src="free_match/V_standard.png" onClick={() => this.onClickFormat(Card.Vanguard_PStandard)} /></li>
					</ul>
				);
			case Card.WS:
				return (
					<ul className="format-list">
						<li><img alt='' src="free_match/neo_standard.png" onClick={() => this.onClickFormat(Card.WS_NeoStandard)} /></li>
						<li><img alt='' src="regulation/standard.png" onClick={() => this.onClickFormat(Card.WS_Standard)} /></li>
					</ul>
				);
			default:
				console.error('指定したカードタイプが存在しません');
				return "";
		}
	}


	private async onClickFormat(format: Card) {
		// イベント送信
		firebase.analytics().logEvent('click_freematch_' + Singleton.getCardFormat(format), {
				nickname: User.nickName,
			});

		let isBattleStart = true;
		
		// イベント情報取得
		const events = await getActiveEvents();
		const result = events.filter((event) => {
			if (format === event.cardType) {
				return true;
			} else {
				return false;
			}
		});

		if (result.length === 1) {
			isBattleStart = await new Promise(resolve => {
				Dialogs.Normal.show(result[0].attention, "キャンセル", "対戦開始",
					() => resolve(true), () => resolve(false));
			});
			Singleton.closeVariousDialog();
		}
		if (isBattleStart) {
			// マッチング待ちに遷移
			this.props.history.push("/battle-wait?card=" + format + "&from=format-select?card=" + this.cardType);
		}
	}
}