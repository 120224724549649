import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import jwt_decode, { JwtPayload } from "jwt-decode";
import { envValue } from '../tools/environment';
import { Link } from 'react-router-dom';
import '../css/main.css';
import '../css/auth.css';
import { Defines } from '../logics/Define';
import { Utility } from '../logics/Utility';
import firebase from '../firebase';

export function Auth() {
    const history = useHistory();

    const [error, setError] = useState('');

    // get the parameter of URL
    const query = new URLSearchParams(useLocation().search);
    const authCode = query.get('code');
    useEffect(() => {
        if(authCode) {
            const redirectUri = envValue.REDIRECT_URI
            const channelId = envValue.CHANNEL_ID;
            const channelSecret = envValue.CHANNEL_SECRET;
            const getTokenUrl = envValue.GETTOKEN;
            const baseData = envValue.BASE;

            if (!redirectUri || !channelId || !channelSecret || !getTokenUrl || !baseData) {
                setError('バックエンドサーバに異常があります。管理者に問い合わせてください。')
                return;
            }

            const formData = baseData.replace("AUTHCODE", authCode).replace("REDIRECTURI", redirectUri).replace("CHANNELID", channelId).replace("CHANNELSECRET", channelSecret);

            const getAndRegister = async () => {
                const received = await getAccessToken(getTokenUrl, formData);
                const postRef = firebase.firestore().collection('user').doc(received.lineId);
                const postDoc = await postRef.get();
                if (postDoc.exists) {
                    console.log('Already exist member.')
                    getCustomToken(received.lineId)
                    .then((res) => {
                        postRef.update({
                            lineAccessToken: received.access_token,
                            lineRefreshToken: received.refresh_token,
                            updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
                        });
                        history.push("/");
                    })
                } else {
                    console.log('New Member!')
                    const fbToken = await getCustomToken(received.lineId);
                    const userRef = firebase.firestore().collection('user').doc(received.lineId);
                    userRef.set({
                        lineAccessToken: received.access_token,
                        lineRefreshToken: received.refresh_token,
                        lineId: received.lineId,
                        lineIdHash: Utility.getHash(received.lineId),
                        firebaseToken: fbToken?.uid,
                        userId: '',
                        created: firebase.firestore.FieldValue.serverTimestamp(),
                        updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
                        loginedAt: firebase.firestore.FieldValue.serverTimestamp(),
                    })
                    history.push("/register", {lineId: received.lineId});
                }
            }
            getAndRegister();
        } else {
            setError('LINEアクセストークンを正常に取得できませんでした。')
        }

        const cleanup = () => {
            //this method is needed in hooks.
        };
        return cleanup;
    }, [authCode, history])
    return (
        <div className="auth" style={{background: "url(/bg/background_TOP.png) no-repeat left top/cover"}}>
        <img alt='' className="logo" src="logo/REMOTAKU_LOGO.png" />
        {Defines.Application.IsDebugMode && <div className="beta">β版(ver{Defines.Application.Version})</div>}
        <div className="loading">読み込み中です</div>
        {error && <div className="error">{error}</div>}
        <Link className="button-back" to="/"><img alt='' src="button/modoru.png" /></Link>
        <div className="footer">
            <ul className="navi">
                <li className="margin-right"><a className="margin-right" href="./howtouse.html">使い方</a>|</li>
                <li className="margin-right"><a className="margin-right" href="./privacy.html">プライバシーポリシー</a>|</li>
                {/* <li className="margin-right"><a className="margin-right" href="">利用規約</a>|</li> */}
                <li className="margin-right"><a className="margin-right" href="./faq.html">FAQ</a>|</li>
                <li><a href="https://lin.ee/wXY9hSy">お問い合わせ</a></li>
            </ul>
            <img alt='' className="copyright" src="logo/footer_toyger-logo.png" />
        </div>
    </div>
    )
}
type ReceivedToken = {
    access_token: string,
    refresh_token: string,
    lineId: string
}
async function getAccessToken(url: string, formData: string): Promise<ReceivedToken> {
    return fetch(url, {
        method: 'POST',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        body: formData
    })
        .then((response) => response.json())
        .then((responseJson) => {
            let receivedToken: ReceivedToken = {
                access_token: responseJson.access_token,
                refresh_token: responseJson.refresh_token,
                lineId: responseJson.id_token
            };

            const decoded = jwt_decode<JwtPayload>(receivedToken.lineId).sub;
            if (decoded) {
                receivedToken.lineId = decoded;
            }
            return receivedToken;
        })
        .catch((error) => {
            console.error(error);
            const errResult = {
                access_token: '',
                refresh_token: '',
                lineId: ''
            };
            return errResult;
        });
}

export async function getCustomToken(lineId: string) {
    // call createCustomToken on Cloud functions
    const functions = firebase.functions();
    const call = functions.httpsCallable("processSignUp");
    const res = await call({ id: lineId });

    const auth = firebase.auth();
    const fbToken = auth.signInWithCustomToken(res.data)
        .then((userCredential) => {
            const fbToken = userCredential.user;
            return fbToken;
        })
    return fbToken;
}

export async function signInExistUser(lineId: string) {
    // call getUser on Cloud functions
    const functions = firebase.functions();
    const call = functions.httpsCallable("getExistFirebaseToken");
    const res = await call({ id: lineId });

    const auth = firebase.auth();
    const fbToken = auth.signInWithCustomToken(res.data)
        .then((userCredential) => {
            const fbToken = userCredential.user;
            return fbToken;
        })
    return fbToken;
}

export async function isRegistUser(uid: string): Promise<boolean> {
    const postRef = firebase.firestore().collection('user').doc(uid);
    const postDoc = await postRef.get();
    if (!postDoc.get('userId')) {
        return false;
    } else {
        return true;
    }
}
