import * as React from 'react';
import {Singleton} from '../logics/Singleton';

import '../css/dialogs.css';

export const Dialogs = {
    Normal: {
        show(text: string, negativeButtonText: string, positiveButtonText: string, onPositive: () => void, onNegative: () => void) {
            Singleton.showVariousDialog({
                body: getNormalBody(text, negativeButtonText, positiveButtonText, onPositive, onNegative),
            });
        }
    },
    NormalUrl: {
        show(text: string, url: string, negativeButtonText: string, positiveButtonText: string, onPositive: () => void, onNegative: () => void) {
            Singleton.showVariousDialog({
                body: getNormalUrlBody(text, url, negativeButtonText, positiveButtonText, onPositive, onNegative),
            });
        }
    },
    SelectDevice: {
        show(videoDevices: string[], audioDevices: string[], videoDeviceIds: string[], audioDeviceIds: string[], onSelect: (videoDevice: number, audioDevice: number) => void) {
            Singleton.showVariousDialog({
                body: getSelectDeviceBody(videoDevices, audioDevices, videoDeviceIds, audioDeviceIds, onSelect),
            });
        }
    },
    SelectCamera: {
        show(videoDevices: string[], videoDeviceIds: string[], onSelect: (videoDevice: number) => void) {
            Singleton.showVariousDialog({
                body: getSelectCameraBody(videoDevices, videoDeviceIds, onSelect),
            });
        }
    },
}

function getNormalBody(text: string, negativeButtonText: string, positiveButtonText: string, onPositive: () => void, onNegative: () => void) {
    return (
        <div className='dialog-root'>
            <div className='normal'>
                <span className='textMessage'>{text}</span>
                <div>
                <button className='cancelBottun' onClick={onNegative}> {negativeButtonText} </button>
                <button className='okBottun' onClick={onPositive}> {positiveButtonText} </button>
                </div>
            </div>
        </div>
    )
}

function getNormalUrlBody(
	text: string,
	url: string,
	negativeButtonText: string,
	positiveButtonText: string,
	onPositive: () => void,
	onNegative: () => void
) {
	return (
		<div className="dialog-root">
			<div className="normal">
				<span className="textMessage">{text}</span>
				<a
					className="url"
					href={url}
					target="_blank"
					rel="noopener noreferrer"
				>
					{url}
				</a>
				<div>
					<button className="cancelBottun" onClick={onNegative}>
						{negativeButtonText}
					</button>
					<button className="okBottun" onClick={onPositive}>
						{positiveButtonText}
					</button>
				</div>
			</div>
		</div>
	);
}

function startVolumeMeter(stream: MediaStream, refVolumeMeter: any) {
    const audioContext = new (window.AudioContext)();

    const analyser = audioContext.createAnalyser();
    const audioSource = audioContext.createMediaStreamSource(stream);
    const audioGain = audioContext.createGain();

    const audioChannelSplitter = audioContext.createChannelSplitter(audioSource.channelCount);

    analyser.minDecibels = -100;
    analyser.maxDecibels = 0;

    analyser.smoothingTimeConstant = 0.8;
    analyser.fftSize = 32;

    audioSource.connect(analyser);
    audioSource.connect(audioGain);

    audioGain.connect(audioChannelSplitter);
    audioGain.connect(audioContext.destination);
    audioGain.gain.value = 0;

    const canvasContext = refVolumeMeter.current.getContext('2d');

    const BASE_X = 0;
    const MAX_X = 80;
    const WIDTH_X = BASE_X + MAX_X;

    let maxRatio = 0;
    let maxValue = 0;

    const calculateAudioLevels = () => {
        const freqs = new Uint8Array(analyser.frequencyBinCount);
        analyser.getByteFrequencyData(freqs);
        let value = 0;

        for (let i = 0; i < analyser.frequencyBinCount; ++i) {
            value = Math.max(value, freqs[i]);
        }

        const volumeRatio = value / 256;

        maxRatio = Math.max(maxRatio, volumeRatio);
        maxValue = Math.max(maxValue, value);
        const meterWidth = WIDTH_X * volumeRatio;

        let meterColor;
        if (0.9 < volumeRatio) {
            meterColor = '#FF3860';
        } else {
            meterColor = '#47CF73';
        }

        canvasContext.clearRect(0, 0, 80, 30);

        canvasContext.fillStyle = meterColor;
        canvasContext.fillRect(0, 0, meterWidth, 30);

        requestAnimationFrame(calculateAudioLevels);
    };

    calculateAudioLevels();
}

function getSelectDeviceBody(videoDevices: string[], audioDevices: string[], videoDeviceIds: string[], audioDeviceIds: string[], onSelect: (videoDevice: number, audioDevice: number) => void){
    const refVideoSelect: React.RefObject<HTMLSelectElement> = React.createRef();
    const refAudioSelect: React.RefObject<HTMLSelectElement> = React.createRef();
    let refLocalVideo: React.RefObject<HTMLVideoElement> = React.createRef();
    let refVolumeMeter: React.RefObject<HTMLCanvasElement> = React.createRef();
    let localStream: MediaStream;
    navigator.mediaDevices
                .getUserMedia({
                    audio: false,
                    video: {
                        deviceId: { exact: [videoDeviceIds[0]] }
                    }
                })
                .then(stream => {
                    const video = refLocalVideo.current;
                    localStream = stream;
                    if (video) {
                        video.srcObject = stream;
                        video.play();
                    }
                })
                .catch((err) => {
                    console.error("CamError1:" + err);
                });
    let localAudioStream: MediaStream;
    navigator.mediaDevices
                .getUserMedia({
                    audio: {
                        deviceId: { exact: [audioDeviceIds[0]] }
                    },
                    video: false
                })
                .then(stream => {
                    localAudioStream = stream
                    startVolumeMeter(localAudioStream, refVolumeMeter)
                })
                .catch((err) => {
                    console.error("AudioErr:" + err);
                });

    return (
        <div className="dialog-root">
            <div className="select-device" >
                {videoDevices.length > 1 &&
                    <>
                        <p className="item">カメラを選択</p>
                        <select ref={refVideoSelect} onChange={() => {
                            let videoId = 0;
                            if(refVideoSelect.current){
                                videoId = refVideoSelect.current.value as unknown as number;
                            }
                            if (refLocalVideo.current) {
                                const video = refLocalVideo.current;
                                if (localStream) {
                                    localStream.getTracks().forEach((track) => { track.stop() });
                                }

                                navigator.mediaDevices
                                    .getUserMedia({
                                        audio: false,
                                        video: {
                                            deviceId: { exact: [videoDeviceIds[videoId]] }
                                        }
                                    })
                                    .then(stream => {
                                        localStream = stream
                                        video.srcObject = stream;
                                        video.play();
                                    })
                                    .catch((err) => {
                                        console.error("CamError1:" + err);
                                    });
                            }
                        }}>
                            {
                                videoDevices.map((_, i) => {
                                    return <option key={"selectdevicevideo_option"+i} value={i}>{_}</option>
                                })
                            }
                        </select>
                    </>
                }
                <div className="video-preview">
                    <video ref={refLocalVideo} playsInline muted={true} autoPlay />
                </div>
                <p className="item">マイクを選択</p>
                <select ref={refAudioSelect} onChange={() => {
                            let audioId = 0;
                            if(refAudioSelect.current){
                                audioId = refAudioSelect.current.value as unknown as number;
                            }
                            if (refVolumeMeter.current) {
                                if (localAudioStream) {
                                    localAudioStream.getTracks().forEach((track) => { track.stop() });
                                }

                                navigator.mediaDevices
                                    .getUserMedia({
                                        audio: {
                                            deviceId: { exact: [audioDeviceIds[audioId]] }
                                        },
                                        video: false
                                    })
                                    .then(stream => {
                                        localAudioStream = stream
                                        startVolumeMeter(localAudioStream, refVolumeMeter)
                                    })
                                    .catch((err) => {
                                        console.error("AudioErr:" + err);
                                    });
                            }
                        }}>
                    {
                        audioDevices.map((_, i) => {
                            return <option key={"selectdeviceaudio_option"+i} value={i}>{_}</option>
                        })
                    }
                </select>
                <canvas ref={refVolumeMeter} id="meter" className="volume-meter" width="80" height="30"></canvas>
                <p className="comment">カメラ、マイクの切り替えは画面上の<br></br>歯車アイコンの設定ボタンからいつでも行う事ができます。</p>
                
                <button onClick={() => {
                    if (localStream) {
                        localStream.getTracks().forEach((track) => { track.stop() });
                    }
                    if (localAudioStream) {
                        localAudioStream.getTracks().forEach((track) => { track.stop() });
                    }
                    Singleton.closeVariousDialog();
                    let videoId = 0;
                    let audioId = 0;
                    if(refVideoSelect.current){
                        videoId = refVideoSelect.current.value as unknown as number;
                    }
                    if(refAudioSelect.current){
                        audioId = refAudioSelect.current.value as unknown as number;
                    }
                    onSelect(videoId, audioId);
                }}>
                    決定
                </button>
            </div>
        </div>
    )
}

function getSelectCameraBody(videoDevices: string[], videoDeviceIds: string[], onSelect: (videoDevice: number) => void) {
    const refVideoSelect: React.RefObject<HTMLSelectElement> = React.createRef();
    let refLocalVideo: React.RefObject<HTMLVideoElement> = React.createRef();
    let localStream: MediaStream;
    navigator.mediaDevices
                .getUserMedia({
                    audio: false,
                    video: {
                        deviceId: { exact: [videoDeviceIds[0]] }
                    }
                })
                .then(stream => {
                    const video = refLocalVideo.current;
                    localStream = stream;
                    if (video) {
                        video.srcObject = stream;
                        video.play();
                    }
                })
                .catch((err) => {
                    console.error("CamError1:" + err);
                });
    return (
        <div className="dialog-root">
            <div className="select-device" >
                <p className="item">カメラを選択</p>
                <select ref={refVideoSelect} onChange={() => {
                            let videoId = 0;
                            if(refVideoSelect.current){
                                videoId = refVideoSelect.current.value as unknown as number;
                            }
                            if (refLocalVideo.current) {
                                const video = refLocalVideo.current;
                                if (localStream) {
                                    localStream.getTracks().forEach((track) => { track.stop() });
                                }

                                navigator.mediaDevices
                                    .getUserMedia({
                                        audio: false,
                                        video: {
                                            deviceId: { exact: [videoDeviceIds[videoId]] }
                                        }
                                    })
                                    .then(stream => {
                                        localStream = stream
                                        video.srcObject = stream;
                                        video.play();
                                    })
                                    .catch((err) => {
                                        console.error("CamError1:" + err);
                                    });
                            }
                        }}>
                    {
                        videoDevices.map((_, i) => {
                            return <option key={"selectdevicevideo_option" + i} value={i}>{_}</option>
                        })
                    }
                </select>
                <div className="video-preview">
                    <video ref={refLocalVideo} playsInline muted={true} autoPlay />
                </div>
                <p className="comment">カメラの切り替えは画面上の<br></br>歯車アイコンの設定ボタンからいつでも行う事ができます。</p>

                <button onClick={() => {
                    if (localStream) {
                        localStream.getTracks().forEach((track) => { track.stop() });
                    }
                    Singleton.closeVariousDialog();
                    let videoId = 0;
                    if (refVideoSelect.current) {
                        videoId = refVideoSelect.current.value as unknown as number;
                    }
                    onSelect(videoId);
                }}>
                    決定
                </button>
            </div>
        </div>
    )
}