import firebase from 'firebase/compat';
import "firebase/firestore";

// ID付き部屋情報
export class RoomInfo {
    public docId: string;
    public room: Room;

    public constructor(docId: string, room: Room){
        this.docId = docId;
        this.room = room;
    }
}

// 部屋情報
export class Room {
    public name: string;
    public sentence: string;
    public cardType: number;
    public updatedAt: Date;
    public createdAt: Date;
    public userNum: number;
    public users: RoomUser[];
    public waitMinutes: number = -1;
    public waitedAt: Date;

    public constructor(name: string, sentence: string, cardType: number, updatedAt: Date, createdAt: Date, userNum: number, users: RoomUser[], waitMinutes: number, waitedAt: Date){
        this.name = name;
        this.sentence = sentence;
        this.cardType = cardType;
        this.updatedAt = updatedAt;
        this.createdAt = createdAt;
        this.userNum = userNum;
        this.users = users;
        this.waitMinutes = waitMinutes;
        this.waitedAt = waitedAt;
    }
}

export const roomConverter = {
    toFirestore(room: Room): firebase.firestore.DocumentData {
        const timeStamp = firebase.firestore.FieldValue.serverTimestamp();
        return {
            name: room.name,
            sentence: room.sentence,
            cardType: room.cardType,
            updatedAt: timeStamp,
            createdAt: timeStamp,
            userNum: room.userNum,
            users: room.users.map((u) => {
                return {
                    nickname: u.nickname,
                    pid: u.pid,
                    lineIdHash: u.lineIdHash,
                    updatedAt: firebase.firestore.Timestamp.fromDate(u.updatedAt),
                };
            }),
            waitMinutes: room.waitMinutes,
            waitedAt: timeStamp,
        };
    },
    fromFirestore(
        snapshot: firebase.firestore.QueryDocumentSnapshot,
        options: firebase.firestore.SnapshotOptions
    ): Room {
        const data = snapshot.data(options)!;
        const users: RoomUser[] = [];
        (data.users as firebase.firestore.DocumentData[]).forEach((u) =>
            users.push(
                new RoomUser(u.nickname, u.pid, u.lineIdHash, u.updatedAt.toDate())
            )
        );
        return new Room(
            data.name,
            data.sentence,
            data.cardType,
            data.updatedAt.toDate(),
            data.createdAt.toDate(),
            data.userNum,
            users,
            data.waitMinutes,
            data.waitedAt ? data.waitedAt.toDate() : firebase.firestore.Timestamp.now(),
        );
    }
};

// 部屋に入っているユーザーデータ
export class RoomUser{
    public lineIdHash: string;
    public nickname: string;
    public pid: string;
    public updatedAt: Date;

    public constructor(nickname: string, pid: string, lineIdHash: string, updatedAt: Date){
        this.nickname = nickname;
        this.pid = pid;
        this.lineIdHash = lineIdHash;
        this.updatedAt = updatedAt;
    }
}

export const roomUserConverter = {
    toFirestore(roomUser: RoomUser): firebase.firestore.DocumentData {
        // return { nickname: roomUser.nickname, pid: roomUser.pid, updatedAt: firebase.firestore.FieldValue.serverTimestamp() };
        return {
					nickname: roomUser.nickname,
					pid: roomUser.pid,
					lineIdHash: roomUser.lineIdHash,
					updatedAt: firebase.firestore.Timestamp.fromDate(roomUser.updatedAt),
				};
    },
    fromFirestore(
        snapshot: firebase.firestore.QueryDocumentSnapshot,
        options: firebase.firestore.SnapshotOptions
    ): RoomUser {
        const data = snapshot.data(options)!;
        return new RoomUser(data.nickname, data.pid, data.lineIdHash, data.updatedAt.toDate());
    }
};