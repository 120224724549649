import React from 'react';
import firebase from 'firebase/compat';
import { useEffect } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { confirmFriend } from '../tools/auth';
import { useAuthContext } from './AuthContext';
import { Singleton } from '../logics/Singleton';
import { Dialogs } from './Dialogs';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const PrivateRoute:React.FC<{
    component: React.FC | any;
    path: string;
    exact: boolean;
}> = props => {
  const { user } = useAuthContext();
  useEffect(() => {
    const confirm = async() => {
      if (user) {
        const lineId = user.uid.replace("line:", "");
        const userRef = await firebase.firestore().collection('user').doc(lineId).get();
        const result = await confirmFriend(userRef.data()?.lineAccessToken)
        if (result) {
          return <Route path={props.path} exact={props.exact} component={props.component} />
        } else {
          console.log('Access key of LINE is revoked.')
          Dialogs.Normal.show('LINE公式アカウントと友だちになる、もしくはブロックを解除してください。※2/21に連携アカウントを変更しました。お手数ですがログアウト後、再度LINEログインをしてください。', "キャンセル", "OK",
            () => {
              Singleton.closeVariousDialog();
              if (Singleton.getOfficialAccountId()) {
                window.location.href = "https://line.me/R/ti/p/" + Singleton.getOfficialAccountId();
              }
            },
            () => Singleton.closeVariousDialog());
          firebase.auth().signOut();
        }
      }
    }
    confirm();
  }, [props.component, props.exact, props.path, user])
  return (
    <Route
      render={(routeProps) => {
        return user ? <Route path={props.path} exact={props.exact} component={props.component} /> : <Redirect to="/login" />;
      }}
    />
  );
};

export default PrivateRoute;
