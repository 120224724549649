import React from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Utility } from '../logics/Utility';
import { User } from '../logics/User';
import { Defines } from '../logics/Define';
import { BattleType, Card } from '../logics/Symbols';
import firebase from '../firebase';
import { Room, roomConverter, RoomUser, RoomInfo } from '../logics/Scheme';
import { FirebaseUtil } from '../logics/FirebaseUtil';
import { AuthContext } from "./AuthContext";
import '../css/main.css';
import '../css/battle-wait.css';
import { Dialogs } from './Dialogs';
import { Singleton } from '../logics/Singleton';

interface Props extends RouteComponentProps { }
interface NoMatch {
	id: string,
	matchedAt: number,
}
interface State {
	isLoading: boolean;
	isWait: boolean;
	waitTime: number;
	blockIds: Array<string>
}


export class BattleWait extends React.Component<Props, State> {
	static contextType = AuthContext;

	private cardType: Card | null = null;
	private rooms: RoomInfo[] = [];
	private checkInterval?: number;
	private uid: string | null = null;
	private noMatches: NoMatch[] = [];
	private toBackAdress: string = "";
	private isError: boolean = false;
	private myRoomId: string = "";
	private lineIdHash: string | null = null;

	constructor(props: Props) {
		super(props);
		this.state = {
			isLoading: true,
			isWait: false,
			waitTime: 10,
			blockIds: []
		};

		// 遷移元を取得
		const toBackAdress = Utility.getQueryParams("from", window.location.search);
		if (!toBackAdress) {
			this.isError = true;
			return;
		} else {
			this.toBackAdress = toBackAdress;
		}

		// カードタイプを取得
		const cardType = Utility.getQueryParams("card", window.location.search);
		if (!cardType) {
			this.isError = true;
			return;
		} else {
			this.cardType = parseInt(cardType);
		}

		// 自分のIDをローカルストレージから取得
		// let uid = Utility.getLocalStorage(Defines.LocalStorageKey.uid);
		// if(!uid){
		//     uid = Utility.getUUID();
		//     Utility.setLocalStorage(Defines.LocalStorageKey.uid, uid);
		// }
		// this.uid = uid;
		// NOTE:同じUIDで複数回接続されるとおかしくなる可能性があるため毎回新しく生成する
		this.uid = Utility.getUUID();

		// 非マッチオブジェクト配列をローカルストレージから取得する
		const minute = Defines.Application.IsDebugMode ? 0 : 180; // 指定時間（分）
		const noMatches = Utility.getLocalStorage(
			Defines.LocalStorageKey.noMatches
		);

		if (noMatches) {
			const filteredNoMatches = noMatches.filter((noMatch: NoMatch) => {
				const matchedAt = new Date(noMatch.matchedAt);
				const elapsedMinute = Utility.elapsedMinuteFrom(matchedAt);
				if (!isNaN(elapsedMinute)) {
					if (elapsedMinute >= minute) {
						// 指定時間以上経過している
						return false;
					} else {
						// 指定時間を経過していない
						return true;
					}
				} else {
					return false;
				}
			});
			// 非マッチオブジェクト配列をローカルストレージに設定する
			Utility.setLocalStorage(Defines.LocalStorageKey.noMatches, filteredNoMatches);
			this.noMatches = filteredNoMatches;
		}

		this.getRooms = this.getRooms.bind(this);
		this.selectRoom = this.selectRoom.bind(this);
		this.register2Room = this.register2Room.bind(this);
		this.onClickWait = this.onClickWait.bind(this);
		this.registLINENotify = this.registLINENotify.bind(this);
	}

	public async componentDidMount() {

		// ハッシュを取得
		const { user } = this.context;
		if (user) {
			const lineId = user.uid.replace("line:", "");
			if (lineId === "") {
				this.isError = true;
			} else {
				this.lineIdHash = Utility.getHash(lineId);
			}
		} else {
			this.isError = true;
		}

		if(this.isError){
			this.props.history.replace("");
		}

		// ブロックリスト取得する
		const db = firebase.firestore();
		const authUserId = firebase.auth().currentUser ? firebase.auth().currentUser?.uid : null
		const blockRefs = await db.collection('blocks').where('userId', '==', authUserId).get();
		const blockIds = blockRefs.docs.map((item: any) => {
			return item.data().targetId
		})
		this.setState({ blockIds })
		// 部屋情報を取得
		this.getRooms(blockIds);
	}

	public componentWillUnmount() {
		window.clearInterval(this.checkInterval);
	}

	public render() {
		console.log(this.toBackAdress)
		return <div className="battle-wait">
			<div className="title">対戦相手募集中</div>
			<img alt='' className="loading" src="free_match/load.gif" />
			<Link className="button-back" to={this.toBackAdress}><img alt='' src="button/modoru.png" /></Link>
			<div className='wait-button-area'>
				<div className='oneline'>
					<select name="wait-time" value={this.state.waitTime} onChange={(e) => this.setState({waitTime: Number.parseInt(e.target.value, 10)})}>
						{[10, 20, 30, 40, 50, 60, 80, 100, 120, 140, 160, 180].map(m => {
							return <option value={m} key={"waitTime" + m}>{`${m}分`}</option>
						})}
					</select>
					<div className='text'>分間ブラウザを閉じても募集を続ける</div>
				</div>
				<div className='note'>（マッチングをするとLINEに通知が来ます）</div>
				<button onClick={() => this.onClickWait()}><img alt='' src={!this.state.isWait ? 'button/kaishi.png' : 'button/tekiyochu.png'} /></button>
			</div>
		</div>
	}

	// ルーム一覧を取得
	private getRooms(blockIds: Array<string>) {
		const refThis = this;
		const db = firebase.firestore();
		// const now = new Date();
		// 5分前までに作られたものを取得
		// 待機機能のために作成時間のチェックはオミット
		// const createdLimit_unix = now.getTime() - 60 * 5 * 1000;
		// const createdLimit = new Date(createdLimit_unix);
		db.collection("rooms")
			// カードタイプ、人数、最後のアップデート時間をチェックしてリストアップ
			.where("userNum", "==", 1)
			.where("cardType", "==", refThis.cardType!)
			// .where("createdAt", ">=", createdLimit)
			.orderBy("createdAt", "desc")
			.limit(100)
			.withConverter(roomConverter)
			.get()
			.then(function(querySnapshot) {
				const rooms: RoomInfo[] = [];
				if (querySnapshot.size > 0) {
					querySnapshot.forEach(function(roomSnap) {
						const room = roomSnap.data();
						// アップデート時間が古くないことを確認
						// TODO:ニックネームで自分じゃないことを確認してるけどuidに変更する。ログイン機能が出来たらで良さそう
						if (room.users[0].nickname !== User.nickName &&
							Utility.elapsedSecFrom(room.updatedAt) < Defines.CheckUpdatedAtSeconds &&
							// ブロック対象は除外する
							!blockIds.includes(room.users[0].lineIdHash)) {
							if (room.waitMinutes > 0) {
								// 待機機能使用中
								if (Utility.elapsedMinuteFrom(room.waitedAt) <=  room.waitMinutes) {
									console.log("待機機能使用中のルームがありました：" + roomSnap.id);
									rooms.push(new RoomInfo(roomSnap.id, room));
								}
							} else if (Utility.elapsedSecFrom(room.updatedAt) < Defines.CheckUpdatedAtSeconds) {
								rooms.push(new RoomInfo(roomSnap.id, room));
							}
						}
						// console.log(room);
					});
				}
				refThis.rooms = rooms;
				refThis.setState({ isLoading: false });
				refThis.selectRoom();
			})
			.catch(function(error) {
				console.error("Error getting documents: ", error);
			});
	}

	// 入室するルームを選択する
	private async selectRoom() {
		// 選択されたカードゲームの空きルームがあるか探して対戦開始画面へ
		// 空いていなければ自分で作成して待機
		let isEnter = false;

		// // TODO: ローカルストレージ取得、再構築（3時間以上は削除）
		// const noMatches = Utility.getLocalStorage(Defines.LocalStorageKey.noMatch);
		// console.log(noMatches)
		// console.log(roomInfo.room.users[0].lineIdHash)
		
		if (this.rooms && this.rooms.length > 0) {
			for (const roomInfo of this.rooms) {
				// 非マッチオブジェクト配列とヒットした場合はスルーする
				if (
					this.noMatches.findIndex(
						({ id }) => id === roomInfo.room.users[0].lineIdHash
					) === -1
				) {
					const success = await this.register2Room(roomInfo.docId);
					if (success) {
						// 相手が待機機能使用中だった場合は通知を送る
						if (roomInfo.room.waitMinutes > 0) {
							console.log("LINE通知を送信します");
							this.sendLineNotify(roomInfo.room.users[0].lineIdHash,
								`${Singleton.getSelfAddress()}/battle-view?room=${roomInfo.docId}&type=${BattleType.Free.toString()}&openExternalBrowser=1`, User.nickName);
						}
					
						// 2人揃ったので対戦開始画面へ遷移
						this.props.history.push(`battle-ready?room=${roomInfo.docId}&type=${BattleType.Free.toString()}&from=${this.toBackAdress}`);
						isEnter = true;
						break;
					}
				}
			}
		}

		if (!isEnter) {
			// 新規で部屋を作成する
			const pid = this.uid!;
			const now = new Date();
			const roomUser = new RoomUser(User.nickName, pid, this.lineIdHash!, now);
			const room = new Room("", "", this.cardType!, now, now, 1, [roomUser], -1, now);
			const db = firebase.firestore();
			db.collection("rooms")
				.withConverter(roomConverter)
				.add(room)
				.then((docRef) => {
					console.log("Document written with ID: ", docRef.id);
					// 相手が見つかったか定期的にチェックする
					const id = docRef.id;
					this.myRoomId = id;
					const refThis = this;
					this.checkInterval = window.setInterval(() => {
						const db = firebase.firestore();
						const docRef = db.collection('rooms').doc(id)
						docRef.update({
							updatedAt: firebase.firestore.FieldValue.serverTimestamp()
						}).then(function() {
							docRef.withConverter(roomConverter)
								.get()
								.then(function(querySnapshot) {
									const room = querySnapshot.data();
									if (room!.users.length >= 2) {
										// 非マッチオブジェクト配列をローカルストレージに設定する
										const noMatches = refThis.noMatches.map((noMatch) => ({
											...noMatch,
										}));
										noMatches.push({
											id: room!.users[1].lineIdHash,
											matchedAt: room!.updatedAt.getTime(),
										});
										Utility.setLocalStorage(
											Defines.LocalStorageKey.noMatches,
											noMatches
										);

										// 2人揃ったので対戦開始画面へ遷移
										refThis.props.history.push(`/battle-ready?room=${id}&type=${BattleType.Free.toString()}&from=${refThis.toBackAdress}`);
									}
								})
								.catch(function(error) {
									console.error("Error update documents: ", error);
								});
						})
					}, 3 * 1000)
				})
				.catch((error) => {
					console.error("Error adding document: ", error);
				});
		}
	}

	// 自分の情報を空いている部屋に登録する
	private async register2Room(docId: string): Promise<boolean> {
		try {
			const db = firebase.firestore();
			const roomRef = db.collection("rooms").doc(docId);
			const success = await db.runTransaction<boolean>(async (transaction): Promise<boolean> => {
				const doc = await transaction.get(roomRef.withConverter(roomConverter));
					if (!doc.exists) {
						throw new Error("Document does not exist!");
					}
					// 既に2人いたらアウト
					if (doc.data()!.userNum >= 2) {
						return false;
					}

					const blockRefs = await db
                        .collection("blocks")
                        .where("userIdHash","==", doc.data()!.users[0].lineIdHash)
                        .where("targetId", "==", this.lineIdHash!)
                        .get();
					// console.log(blockRefs.docs[0].data())
                    if (!blockRefs.empty) {
                        return false;
                    }
					
					// 非マッチオブジェクト配列をローカルストレージに設定する
					const noMatches = this.noMatches.map((noMatch) => ({ ...noMatch }));
					noMatches.push({
						id: doc.data()!.users[0].lineIdHash,
						matchedAt: Date.now(),
					});
					Utility.setLocalStorage(Defines.LocalStorageKey.noMatches, noMatches);

					let users = doc.data()!.users;
					const pid = this.uid!;
					users.push({
						nickname: User.nickName,
						pid: pid,
						lineIdHash: this.lineIdHash!,
						updatedAt: new Date(),
					});
					transaction.update(roomRef, {
						userNum: 2,
						users: users.map(u => FirebaseUtil.convertRoomUser(u)),
					});
					return true;
				});
			if (success) {
				console.log("Transaction successfully committed!");
			} else {
				console.log("Transaction failed because room is full");
			}
			return success;
		} catch (e) {
			console.log("Transaction failed: ", e);
			return false;
		}
	}

	// バックグラウンドに行っても待機できるようにする
	// 待機時間の更新は許可（これが何度呼ばれても大丈夫）
	private async onClickWait() {
		// LINENotifyの登録チェック
		const userId = firebase.auth().currentUser?.uid.replace("line:", '');
		const userRef = firebase.firestore().collection('user').doc(userId);
		const userDoc = await userRef.get();
		const token = userDoc.data()?.lineNotifyToken;
		if(token) {
			// 定期的な相手の入室チェックはオフでOK
			// window.clearInterval(this.checkInterval);
			// 部屋に待機情報を登録する
			const db = firebase.firestore();
			const docRef = db.collection('rooms').doc(this.myRoomId);
			docRef.update({
				waitMinutes: this.state.waitTime,
				waitedAt: firebase.firestore.FieldValue.serverTimestamp(),
			});
			//　状態を待機中に
			this.setState({isWait: true});
		} else {
			// LINENotifyの登録を促す
			Dialogs.Normal.show("対戦待機機能を使用するには、LINE通知の登録が必要です", "閉じる", "登録する", () => {this.registLINENotify(); Singleton.closeVariousDialog();}, () => Singleton.closeVariousDialog())
		}
	}

	private registLINENotify() {
		const clientId = Defines.Application.IsDebugMode ? "lK9pX9LWGMcuVV2IxiogFo":"e5d4WO2OagqOVE6CV0S28p";
		const callbackURL =  Defines.Application.IsDebugMode ? "https://toyger-rimotaku-dev.web.app/line-notify-callback":"https://remotaku.com/line-notify-callback" ;
		const CSRFToken = Utility.getHash(firebase.auth().currentUser!.uid);
		const url = `https://notify-bot.line.me/oauth/authorize?response_type=code&client_id=${clientId}&redirect_uri=${callbackURL}&scope=notify&state=${CSRFToken}`;
		window.open(url, "_blank");
	}

	private sendLineNotify(otherHash: string, url: string, nickname: string) {
		const functions = firebase.app().functions("asia-northeast1");
		const call = functions.httpsCallable("sendLineNotify");
		call({hash: otherHash, url: url, nickname: nickname});
	}
}