import { Utility } from '../logics/Utility';
import { Defines } from '../logics/Define';

// ユーザーデータを持つシングルトン
class UserClass {
    private _nickName: string = "";
    get nickName(): string {
        if(this._nickName === ""){
            this._nickName = Utility.getLocalStorage(Defines.LocalStorageKey.nickname);
        }
        if(!this._nickName){
            this._nickName = "NoName";
        }
        return this._nickName;
    }
    set nickName(name: string) {
        this._nickName = name;
    }
}
export const User = new UserClass();
