import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { getFunctions } from 'firebase/functions';
import { Defines } from "./logics/Define";
// 互換用
import firebase from "firebase/compat/app"

// firebaseコンソールから取得
const firebaseConfig = Defines.Application.IsDebugMode ?
    {
        apiKey: "AIzaSyB65z0KAcVDL1WCq-FOrGtUjUCr6JzNpgE",
        authDomain: "toyger-rimotaku-dev.firebaseapp.com",
        projectId: "toyger-rimotaku-dev",
        storageBucket: "toyger-rimotaku-dev.appspot.com",
        messagingSenderId: "939937427403",
        appId: "1:939937427403:web:05f3543a261e92a6b4104e",
        measurementId: "G-G91GMQYJJT"
    } :
    {
        apiKey: "AIzaSyAJ1yvxEo1Ydn7pymOGvGdUQZsxxqcRN1w",
        authDomain: "toyger-rimotaku.firebaseapp.com",
        projectId: "toyger-rimotaku",
        storageBucket: "toyger-rimotaku.appspot.com",
        messagingSenderId: "152874299480",
        appId: "1:152874299480:web:a2c816af23bce6b4cb5a2d",
        measurementId: "G-RQHEEGNPER"
    };

// compat(v8互換)方式の初期化
if (firebase.apps.length === 0) {
    firebase.initializeApp(firebaseConfig);
}

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// 各モジュールの初期化
const db = getFirestore(app);
const auth = getAuth(app);
const functions = getFunctions(app);

export { app, db, auth, functions };
// 互換用
export default firebase;
