export enum Card {
	Yugioh_OCG     			= 10,

	Pokemon 				= 20,
	 Pokemon_Standard 		= 21,
	 Pokemon_Extra 			= 22,

	DuelMasters 			= 30,
	 DuelMasters_Advance 	= 31,
	 DuelMasters_Original 	= 32,
	
	MTG 					= 40,
	 MTG_Standard 			= 41,
	 MTG_Modern 			= 42,
	 MTG_Legacy 			= 43,
	 MTG_Pioneer 			= 44,

	Vanguard				= 60,
	 Vanguard_Standard		= 61,
	 Vanguard_VStandard		= 62,
	 Vanguard_PStandard		= 63,
	
	Digimon					= 70,
	Yugioh_RushDuel			= 80,

	WS						= 90,
	 WS_NeoStandard			= 91,
	 WS_Standard			= 93,

	Shadowverse_EVOLVE		= 100,

	OnePiece				= 110,

	UnionArena				= 120,

	DragonBall				= 130,
}
export enum BattleType {
	Free	= 1,
	Friend	= 2,
}

export enum Event {
	RemoteKingdom = 1,
}