import React, { useState } from 'react';
import firebase from '../firebase';
import { useHistory, useLocation } from 'react-router-dom';
import { Defines } from '../logics/Define';
import '../css/main.css';
import '../css/login.css';
import { updateUser, validator } from '../tools/auth';

interface stateType {
    lineId?: string;
}

export function Register() {
    const history = useHistory();
    const { state } = useLocation<stateType>();
    const [userId, setUserId] = useState<string>('');
    const [pass, setPass] = useState<string>('');
    const [error, setError] = useState<string>();

    const handleChangeUserId = (e: { target: { value: string; }; }) => {
        setUserId(() => e.target.value);
    }

    const handleChangePass = (e: { target: { value: string; }; }) => {
        setPass(() => e.target.value);
    }

    const registUser = async () => {
        const idValidate = validator('id', userId);
        const passValidate = validator('password', pass);
        if (idValidate.result && passValidate.result){
            firebase.auth().fetchSignInMethodsForEmail(userId)
            .then((result) => {
                if (result.length === 0) {
                    const userRef = firebase.firestore().collection('user');
                    userRef.where('userId', '==', userId).get()
                    .then((exist) => {
                        if (!(exist.docs.length === 0)) {
                            console.log('ALREADY EXISTS');
                            setError('このメールアドレスは既に使用されています。');
                        } else {
                            const credential = firebase.auth.EmailAuthProvider.credential(userId, pass);
                            firebase.auth().currentUser?.linkWithCredential(credential);
                            console.log('UPDATE');
                            state.lineId && updateUser(state.lineId, userId, pass);
                            history.push("/");
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                        setError(error.message);
                    })
                } else {
                    setError('登録済みのアドレスです。')
                }
            });
        } else if (!idValidate.result) {
            setError(idValidate.msg);
        } else if (!passValidate.result) {
            setError(passValidate.msg);
        }
    }

    const skip = () => {
        history.push("/");
    }
    
    return (
        <div className="login" style={{background: "url(/bg/background_TOP.png) no-repeat left top/cover"}}>
            <img alt='' className="logo" src="logo/REMOTAKU_LOGO.png" />
            {Defines.Application.IsDebugMode && <div className="beta">β版(ver{Defines.Application.Version})</div>}
            <div className="form">
                <input className="input" type="text" value={userId} onChange={handleChangeUserId} placeholder="メールアドレス"/>
                <input className="input" type="password" value={pass} onChange={handleChangePass} placeholder="パスワード"/>
            </div>
                    <div>
                        <button onClick={registUser}><img alt='' className="touroku-button" src="button/touroku.png"/></button>
                        <button onClick={skip}><img alt='' className="skip-button" src="button/skip.png"/></button>
                    </div>
            {error && <div className="error">{error}</div>
            }
            <div className="footer">
                <ul className="navi">
                    <li className="margin-right"><a className="margin-right" href="./howtouse.html">使い方</a>|</li>
                    <li className="margin-right"><a className="margin-right" href="./privacy.html">プライバシーポリシー</a>|</li>
                    {/* <li className="margin-right"><a className="margin-right" href="">利用規約</a>|</li> */}
                    <li className="margin-right"><a className="margin-right" href="./faq.html">FAQ</a>|</li>
                    <li><a href="https://lin.ee/wXY9hSy">お問い合わせ</a></li>
                </ul>
                <img alt='' className="copyright" src="logo/footer_toyger-logo.png" />
            </div>
        </div>
    )
}
