import React from 'react';
import { createContext, useState, useContext, useEffect } from 'react';
import firebase from 'firebase/compat';

export const defaultState = {
        user: firebase.auth().currentUser,
}

export const AuthContext = createContext(defaultState);

export function useAuthContext() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }:any) {
    const [user, setUser] = useState<firebase.User | null>(null);
    const [loading, setLoading] = useState(true);
    
    const value = {
      user,
      loading,
    };
  
    useEffect(() => {
      const unsubscribed = firebase.auth().onAuthStateChanged((firebaseUser) => {
        setUser(firebaseUser);
        setLoading(false);
      });
      return () => {
        unsubscribed();
      };
    }, []);
  
    return (
      <AuthContext.Provider value={value}>
        {!loading && children}
      </AuthContext.Provider>
    );
  }