import "firebase/firestore";
import { Timestamp, DocumentData } from 'firebase/firestore';
import { RoomUser } from "../logics/Scheme";

export const FirebaseUtil = {
    convertRoomUser(roomUser: RoomUser): DocumentData {
        return {
            nickname: roomUser.nickname,
            pid: roomUser.pid,
            lineIdHash: roomUser.lineIdHash,
            updatedAt: Timestamp.fromDate(
                roomUser.updatedAt
            ),
        };
    },
};
