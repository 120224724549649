import React, { useState } from 'react';
import firebase from '../firebase';
import { useHistory } from 'react-router-dom';
import { Defines } from '../logics/Define';
import btn_login_hover from '../img/btn_login_hover.png';
import { envValue } from '../tools/environment';
import '../css/main.css';
import '../css/login.css';
import { validator } from '../tools/auth';

export function Login() {
    const history = useHistory();
    const [isAccountLogin, setIsAccountLogin] = useState<boolean>(false);
    const [userId, setUserId] = useState<string>('');
    const [pass, setPass] = useState<string>('');
    const [error, setError] = useState<string>();

    const handleChangeUserId = (e: { target: { value: string; }; }) => {
        setUserId(() => e.target.value);
    }

    const handleChangePass = (e: { target: { value: string; }; }) => {
        setPass(() => e.target.value);
    }

    const logIn = async() => {
        const idValidate = validator('id', userId);
        const passValidate = validator('password', pass);
        if (idValidate.result && passValidate.result) {
            firebase.auth().fetchSignInMethodsForEmail(userId)
            .then((result) => {
                if (0 < result.length) {
                    firebase.auth().signInWithEmailAndPassword(userId, pass)
                    .then(() => history.push("/"))
                    .catch((error) => {
                        const errorCode = error.code;
                        if (errorCode === 'auth/user-not-found') {
                            setError('未登録のアドレスです。');
                        } else if (errorCode === "auth/wrong-password"){
                            setError('パスワードが不正です。');
                        }
                    })
                } else {
                    setError('未登録のアドレスです。')
                }
            })
        } else if (!idValidate.result) {
            setError(idValidate.msg);
        } else if (!passValidate.result) {
            setError(passValidate.msg);
        }
    }

    const channelId = envValue.CHANNEL_ID;
    const redirectUri = envValue.REDIRECT_URI;
    const loginUrl = envValue.GETAUTH_URL;
    let lineLoginUrl = '';
    if (!channelId || !redirectUri || !loginUrl) {
        setError('バックエンドサーバに異常があります。管理者に問い合わせてください。')
    } else {
        lineLoginUrl = loginUrl?.replace("CHANNELID", channelId).replace("REDIRECTURI", redirectUri);
    }

    return (
        <div className="login">
            <div className="logo-towa">
                <img alt='' className="logo" src="logo/REMOTAKU_LOGO.png" />
                {Defines.Application.IsDebugMode && <div className="beta">β版(ver{Defines.Application.Version})</div>}
                <img alt='' className="towa" src="REMOTAKU_towa.png" />
            </div>
            <div className="select-login">
                {!history.location.state &&
                    <button className="select-login-button">
                        <a href={lineLoginUrl}>
                            <img alt='' className="select-login-button-image" src="button/first_login.png" />
                            <img alt='' className="line-button-image" src={btn_login_hover} />
                        </a>
                    </button>
                }
                <button className="select-login-button" onClick={() => setIsAccountLogin(!isAccountLogin)}>
                    <img alt='' className="select-login-button-image" src="button/account_login.png" />
                </button>
            </div>
            {isAccountLogin &&
                <React.Fragment>
                    <div className="form">
                        <input className="input" type="text" value={userId} onChange={handleChangeUserId} placeholder="メールアドレス" />
                        <input className="input" type="password" value={pass} onChange={handleChangePass} placeholder="パスワード" />
                    </div>
                    <button onClick={logIn}><img alt='' className="login-button" src="button/login.png" /></button>
                    {error && <div className="error">{error}</div>}
                </React.Fragment>
            }
            <div><a href="./howtouse.html"><img alt='' className="how-play" src="button/goriyou.png" /></a></div>
			<ul className="werning-list">
                <li>
                    <img alt='' className="werning" src="button/exclamation.png" />
                    <p className="text">Android端末のうちGalaxyをお使いの方は<br />ブラウザはFirefoxをお使いください</p>
                </li>
            </ul>
            <div className="footer">
                <ul className="navi">
                    <li className="margin-right"><a className="margin-right" href="./howtouse.html">使い方</a>|</li>
                    <li className="margin-right"><a className="margin-right" href="./privacy.html">プライバシーポリシー</a>|</li>
                    {/* <li className="margin-right"><a className="margin-right" href="">利用規約</a>|</li> */}
                    <li className="margin-right"><a className="margin-right" href="./faq.html">FAQ</a>|</li>
                    <li><a href="https://lin.ee/wXY9hSy">お問い合わせ</a></li>
                </ul>
                <img alt='' className="copyright" src="logo/footer_toyger-logo.png" />
            </div>
        </div>
    )
}
