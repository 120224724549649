import React, { useState } from 'react';
import firebase from '../firebase';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { Defines } from '../logics/Define';
import { Singleton } from '../logics/Singleton';
import { Dialogs } from './Dialogs';
import '../css/main.css';
import '../css/login.css';
import { validator, updateUser } from '../tools/auth';
import { useAuthContext } from './AuthContext';

export function Reset() {
    const history = useHistory();
    const { user } = useAuthContext();
    const [email, setEmail] = useState<string>('');
    const [pass, setPass] = useState<string>('');
    const [error, setError] = useState<string>();

    const handleChangeEmail = (e: { target: { value: string; }; }) => {
        setEmail(() => e.target.value);
    }

    const handleChangePass = (e: { target: { value: string; }; }) => {
        setPass(() => e.target.value);
    }

    const resetCredential = async () => {
        if (!firebase.auth().currentUser) {
            Dialogs.Normal.show('セッションタイムアウトです。再度ログインしてください。', "キャンセル", "OK",
                () => {
                Singleton.closeVariousDialog();
                window.location.href = "/";
                },
                () => Singleton.closeVariousDialog()
            );

            return;
        }

        const emailValidate = validator('id', email);
        const passValidate = validator('password', pass);
        if (!user?.email && !emailValidate.result) {
            // メールアドレスが設定されていない場合はパスワードも設定できない
            setError('※メールアドレスが登録されていません。\nメールアドレスとパスワードを設定してください。')
        } else {
            if (!user?.email) {
                // 初回入力時はID・パス両方必要
                console.log("新規登録します");
                if (emailValidate.result && passValidate.result){
                    firebase.auth().fetchSignInMethodsForEmail(email)
                    .then((result) => {
                        if (result.length === 0) {
                            const userRef = firebase.firestore().collection('user');
                            userRef.where('userId', '==', email).get()
                            .then((exist) => {
                                if (!(exist.docs.length === 0)) {
                                    console.log('ALREADY EXISTS');
                                    setError('このメールアドレスは既に使用されています。');
                                } else {
                                    const credential = firebase.auth.EmailAuthProvider.credential(email, pass);
                                    firebase.auth().currentUser?.linkWithCredential(credential);
                                    console.log('UPDATE');
                                    updateUser(user!.uid.replace("line:", ""), email, pass);
                                    history.push("/");
                                }
                            })
                            .catch((error) => {
                                console.log(error);
                                setError(error.message);
                            })
                        } else {
                            setError('登録済みのアドレスです。')
                        }
                    });
                } else if (!emailValidate.result) {
                    setError(emailValidate.msg);
                } else if (!passValidate.result) {
                    setError(passValidate.msg);
                }
            } else {
                let errorMsg = "";
                let isUpdateEmail = false;
                let isUpdatePass = false;

                // メールアドレス変更
                if (email) {
                    if (user && emailValidate.result) {
                        isUpdateEmail = true;
                    } else if (email !== '' && !emailValidate.result) {
                        errorMsg += emailValidate.msg + "\n";
                    }
                }

                // パスワード変更
                if (pass) {
                    if (user && passValidate.result) {
                        isUpdatePass = true;
                    } else if (pass !== "" && !passValidate.result) {
                        errorMsg += passValidate.msg;
                    }
                }

                // メールアドレスとパスワード両方未入力時のメッセージ表示
                if (!email && !pass) {
                    errorMsg += "変更内容を入力してください。"
                }

                if (!emailValidate.result && !passValidate.result) {
                    console.log("入力なし");
                    errorMsg = emailValidate.msg + "\n" + passValidate.msg;
                }

                if (errorMsg !== "") {
                    // エラー表示
                    setError(errorMsg);
                } else {
                    let endUpdateEmail = false;
                    if (isUpdateEmail) {
                        firebase.auth().fetchSignInMethodsForEmail(email)
                            .then((result) => {
                                if (result.length === 0) {
                                    const userRef = firebase.firestore().collection('user');
                                    userRef.where('userId', '==', email).get()
                                        .then((exist) => {
                                            if (!(exist.docs.length === 0)) {
                                                console.log('ALREADY EXISTS');
                                                errorMsg = 'このメールアドレスは既に使用されています。';
                                                setError(errorMsg);
                                                endUpdateEmail = true;
                                            } else {
                                                user!.updateEmail(email);
                                                userRef.doc(user!.uid.replace("line:", "")).update({
                                                    updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
                                                    userId: email
                                                }).then(() =>{
                                                    endUpdateEmail = true;
                                                    console.log('UPDATE EMAIL');
                                                })
                                            }
                                        })
                                        .catch((error) => {
                                            console.log(error);
                                            errorMsg = error.message;
                                            setError(errorMsg);
                                            endUpdateEmail = true;
                                        })
                                } else {
                                    errorMsg = '登録済みのアドレスです。';
                                    setError(errorMsg);
                                    endUpdateEmail = true;
                                }
                            });
                    } else {
                        endUpdateEmail = true;
                    }

                    const waitSetEmail = () => new Promise<void>(resolve => {
                        const id = window.setInterval(() => {
                            if(endUpdateEmail) {
                                resolve();
                                window.clearInterval(id);
                            }
                        }, 100)
                    });
                    await waitSetEmail();

                    if (isUpdatePass && !errorMsg) {
                        if (isUpdateEmail) {
                            // メールアドレスと同時に変更すると怒られるということなので、待ちを入れている・・・
                            const sleep = (sec: number) => new Promise(resolve => setTimeout(resolve, sec));
                            await sleep(1000);
                            console.log("待ちました");
                        }
                        try {
                            await user!.updatePassword(pass);
                        } catch (e) {
                            Dialogs.Normal.show('セッションタイムアウトです。再度ログインしてください。', "キャンセル", "OK",
                                () => {
                                Singleton.closeVariousDialog();
                                window.location.href = "/";
                                },
                                () => Singleton.closeVariousDialog()
                            );

                            return;
                        }
                        console.log('UPDATE PASSWORD');
                        const userRef = firebase.firestore().collection('user');
                        userRef.doc(user!.uid.replace("line:", "")).update({
                            updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
                        })
                    }

                    console.log('END UPDATE');
                    if (!errorMsg) {
                        history.push("/");
                    }
                }
            }
        }

    }

    return (
        <div className="login" style={{background: "url(/bg/background_TOP.png) no-repeat left top/cover"}}>
            <img alt='' className="logo" src="logo/REMOTAKU_LOGO.png" />
            {Defines.Application.IsDebugMode && <div className="beta">β版(ver{Defines.Application.Version})</div>}
            <div className="form">
                <input className="input" type="email" value={email} onChange={handleChangeEmail} placeholder="メールアドレス"/>
                <input className="input" type="password" value={pass} onChange={handleChangePass} placeholder="パスワード"/>
            </div>
                    <div>
                        <button onClick={resetCredential}><img alt='' className="saisettei-button"  src="button/toroku_johou_sai_settei.png" /></button> 
                        <Link className="button-back" to="/"><img alt='' src="button/modoru.png" /></Link>
                    </div>
            {error && <div className="error"><pre>{error}</pre></div>
            }
            <div className="footer">
                <ul className="navi">
                    <li className="margin-right"><a className="margin-right" href="./howtouse.html">使い方</a>|</li>
                    <li className="margin-right"><a className="margin-right" href="./privacy.html">プライバシーポリシー</a>|</li>
                    {/* <li className="margin-right"><a className="margin-right" href="">利用規約</a>|</li> */}
                    <li className="margin-right"><a className="margin-right" href="./faq.html">FAQ</a>|</li>
                    <li><a href="https://lin.ee/wXY9hSy">お問い合わせ</a></li>
                </ul>
                <img alt='' className="copyright" src="logo/footer_toyger-logo.png" />
            </div>
        </div>
    )
}