import React from 'react';
import { Link } from 'react-router-dom';

import '../css/main.css';
import '../css/battle-select.css';

export function BattleSelect() {
    return (
        <div className="battle-select" style={{background: "url(/bg/background_2.png) no-repeat left top/cover"}}>
            <div className="title">対戦種類の選択</div>
            <div><img alt='' className="sankaku" src="button/sankaku.png" /></div>
            <Link className="button-normal" to="/free-match"><img alt='' src="button/free-match.png" /></Link>
            <Link className="button-normal" to="/friend-match?canSpectate=false"><img alt='' src="button/friend_match_NASHI.png" /></Link>
            <Link className="button-normal" to="/friend-match?canSpectate=true"><img alt='' src="button/friend_match_ARI.png" /></Link>
            <Link className="button-normal" to="/event-select"><img alt='' src="button/event.png" /></Link>
			<p className="announcement">↑↑↑</p>
			<p className="announcement">Remote Kingdomに参加する場合はこちら</p>
            <Link className="button-back" to="/nickname"><img alt='' src="button/modoru.png" /></Link>
        </div>
    )
}
