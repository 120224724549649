import { db, app } from '../firebase';
import { updateDoc, doc, serverTimestamp, getDoc } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { envValue } from '../tools/environment';

// ユーザー情報の更新
export async function updateUser(lineId: string, userId: string, pass: string) {
    const userRef = doc(db, 'user', lineId);
    await updateDoc(userRef, {
        userId: userId,
        updatedAt: serverTimestamp()
    });
}

// // LINEアクセストークンの取得
// export async function getLineAccessToken(): Promise<string> {
//     const lineId = getAuth(app).currentUser?.uid.replace("line:", "");
//     const postRef = await getDoc(doc(db, 'user', lineId));
//     if (!postRef) {
//         return "cannot get this line user's access token"
//     }
//     return postRef.data()?.lineAccessToken
// }

// LINEアクセストークンの取得
export async function getLineAccessToken(): Promise<string> {
    const lineId = getAuth(app).currentUser?.uid.replace("line:", "");
    if (!lineId) {
        return "cannot get this line user's access token";
    }
    const postRef = await getDoc(doc(db, 'user', lineId));
    if (!postRef.exists()) {
        return "cannot get this line user's access token";
    }
    return postRef.data()?.lineAccessToken || "cannot get this line user's access token";
}

// 友達確認
export async function confirmFriend(token: string): Promise<boolean> {
    const bearer_token = 'Bearer ' + token;
    return fetch(envValue.CONFIRM_URL, {
        method: 'GET',
        headers: { 'Authorization': bearer_token}
    })
    .then((response) => response.json())
    .then((responseJson) => {
        if (responseJson.message === "The access token revoked" || responseJson.friendFlag === false) {
            return false;
        }
        return true;
    })
    .catch((error) => {
        console.error(error);
        return false;
    });
}

interface ValidateResult {result: boolean, msg: string};
export function validator(type: string, value: string): ValidateResult {
    // If you want to make each alphanumeric character mandatory, use below
    //const regex = /^(?=.*?[a-zA-Z])(?=.*?\d)[a-zA-Z\d]{8,30}$/;
    let regex;
    let error;
    switch (type) {
        case 'id':
            if (!value) {
                error = '※メールアドレスを入力してください';
                return {result: false, msg: error};
            }
            regex = /^[a-zA-Z0-9-_.]+@[a-zA-Z0-9-_.]+$/;
            if (!regex.test(value)) {
                error = '※正しいメールアドレスを入力してください';
                return {result: false, msg: error};
            }
            return {result: true, msg: 'success'};
        case 'password':
            if (!value) {
                error = '※パスワードを入力してください';
                return {result: false, msg: error};
            }
            regex = /^[a-zA-Z0-9\d]{8,30}$/;
            if (!regex.test(value)) {
                error = '※パスワードは8文字以上30文字以下の英数字で入力してください';
                return {result: false, msg: error};
            }
            return {result: true, msg: 'success'};
    }
    error = 'not correct type';
    return {result: false, msg: error};
}

// export const setLineIdHashForAllUser = async () => {
// 	const batches: firebase.firestore.WriteBatch[] = [];
// 	batches.push(firebase.firestore().batch());
// 	let counter = 0;
// 	let index = 0;

// 	const userDocs = await db.collection("user").get();
// 	userDocs.forEach((userDoc) => {
// 		batches[index].update(userDoc.ref, {
// 			lineIdHash: Utility.getHash(userDoc.data().lineId),
// 			updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
// 		});
// 		counter++;

// 		if (counter === 499) {
// 			batches.push(firebase.firestore().batch());
// 			index++;
// 			counter = 0;
// 		}
// 	});

// 	batches.forEach(async (batch) => await batch.commit());
// };
