import { Route, Redirect } from 'react-router-dom';
import { useAuthContext } from './AuthContext';
const PublicRoute:React.FC<{
    component: React.FC | any;
    path: string;
}> = props => {
  const { user } = useAuthContext();
  return (
    <Route
      render={(routeProps) => {
        return !user ? <Route path={props.path} component={props.component} /> : <Redirect to="/" />;
      }}
    />
  );
};

export default PublicRoute;