import * as QueryString from 'query-string';
import { v4 as uuidv4 } from 'uuid';
import { createHash } from 'crypto'

export const Utility = {
    getQueryParams(key: string, url: string): string | null {
        const query = QueryString.parse(url);
        if (key in query) {
            return query[key]!.toString();
        } else {
            return null;
        }
    },

    // 一定時間待つ
    sleep(msec : number = 1) {
        return new Promise(resolve => setTimeout(resolve, msec));
    },

    // nullもしくは空、もしくは空白のみ・スペースのみであることを判定
    isNullOrSpace(str: string) {
        // まずは普通に判定
        if (!str) {
            return true;
        }
        // 改行とスペースなど
        return str.replace(/\n/g, "").replace(/ /g, "").replace(/　/g, "").replace(/\b/g, "").replace(/\t/g, "") === "";
    },
   
     //
    // 現在から何秒後かを返却
    //
    elapsedSecUntil(date: Date): number {
        return Math.floor((date.getTime() - new Date().getTime()) / 1000);
    },

    //
    // 現在から何秒前かを返却
    //
    elapsedSecFrom(date: Date): number {
        return Math.floor((new Date().getTime() - date.getTime()) / 1000);
    },

    //
    // 現在から何分前かを返却
    //
    elapsedMinuteFrom(date: Date): number {
        return Math.floor(this.elapsedSecFrom(date) / 60);
    },

    //
    // 現在から何時間前かを返却
    //
    elapsedHourFrom(date: Date): number {
        return Math.floor(this.elapsedMinuteFrom(date) / 60);
    },

    //
    // 現在から何日前かを返却
    //
    elapsedDayFrom(date: Date): number {
        return Math.floor(this.elapsedSecFrom(date) / (60 * 60 * 24));
    },

    // 現在から何日後かを返却
    elapsedDayUntil(date: Date): number {
        return Math.floor(this.elapsedSecUntil(date) / (60 * 60 * 24));
    },

    isiOS(): boolean{
        const userAgent = navigator.userAgent || '';
        const isiOS = userAgent.indexOf("iPhone") !== -1 || userAgent.indexOf("iPad") !== -1 || userAgent.indexOf("iPod") !== -1;
        return isiOS;
    },

    isAndroid(): boolean {
        const ua = navigator.userAgent.toLowerCase();
        return /android/.test(ua);
    },

    // PCか
    isPC(): boolean {
        if(!(navigator.userAgent.match(/(iPhone|iPad|iPod|Android)/i))) {
            return true;
        }
        return false;
    },

    // iOSのSafariである
    isiOSSafari(): boolean{
        const userAgent = navigator.userAgent || '';
        // Chrome iOS
        const isCriOS = userAgent.match(/crios/i) !== null;
        // Firefox iOS
        const isFxiOS = userAgent.match(/fxios/i) !== null;
        const isSafari = (navigator.vendor && navigator.vendor.indexOf('Apple') !== -1) && !isCriOS && !isFxiOS;
        const isSafariMobile = (isSafari && (userAgent.indexOf('Mobile/') !== -1));

        if(isSafariMobile){
            return isSafariMobile;
        }

        return false;
    },

    getiOSVersion(): string | null {
        if(!this.isiOS()){
            return null;
        }
        const userAgent = (navigator.userAgent || '').toLowerCase();
        const version = userAgent.match(/iphone os ([\d_]+)/);
        if (version) {
            return version[1].replace(/_/g,".");
        }
        return null;
    },
    
    // スタンドアローンか
    isStandalone(): boolean{
        return ((navigator as any).standalone === true);
    },

    clearAllTimeouts() {
        let t = window.setTimeout(() => { }, 10);
        while (t--) {
            window.clearTimeout(t);
        }
    },

    // videoタグの解放処理
    releaseVideoTag(video: HTMLVideoElement, removeElement: boolean = true) {
        try {
            video.pause();
            if (removeElement) {
                let child = video.firstChild;
                while (child) {
                    child.remove();
                    child = video.firstChild;
                }
            } else {
                video.childNodes.forEach(e => {
                    const source = e as HTMLElement;
                    if (source) {
                        source.removeAttribute('src');
                    }
                });
            }
            video.removeAttribute('src');
            video.removeAttribute('poster');
            video.load();
            if (removeElement) {
                video.remove();
            }
        } catch{ }
    },

    zeroPadding(num: number, length: number,padChar: string = "0") {
        let str = "";
        for (let i = 0; i < length; i++) {
            str += padChar;
        }
        str += num;
        return str.slice(-length);
    },

    getUUID(): string {
        return uuidv4().replace(/-/g, "");
    },

    // ハッシュ化済み文字列を返却
    getHash(str: string): string {
        return createHash("sha256").update(str).digest("hex");
    },

    getLocalStorage(localStorageKey: string): any | null {
        const str = window.localStorage.getItem(localStorageKey);
        if (str) {
            try {
                const parse = JSON.parse(str);
                if (parse) {
                    return parse;
                }
            } catch { }
        }
        return str;
    },

    setLocalStorage(localStorageKey: string, value: any) {
        window.localStorage.setItem(localStorageKey, JSON.stringify(value));
    },

    removeLocalStorage(localStorageKey: string): void {
        window.localStorage.removeItem(localStorageKey);
    },
};