import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Utility } from '../logics/Utility'
import { BattleType } from '../logics/Symbols';

import '../css/main.css';
import '../css/battle-ready.css';

interface Props extends RouteComponentProps{}
interface State{}
export class BattleReady extends React.Component<Props, State> {
    private roomId: string | null = null;
    private nickname: string | null = null;
    private from: string | null = null;

    constructor(props: Props) {
        super(props);
        this.roomId = Utility.getQueryParams("room", window.location.search);
        this.nickname = Utility.getQueryParams("name", window.location.search);
        this.from = Utility.getQueryParams("from", window.location.search);
        // ルーム情報が無い場合は戻す
        if(!(this.roomId)){
            this.props.history.replace("free-match");
        }

        this.startBattle = this.startBattle.bind(this);
    }

    public render() {
        return <div className="battle-ready" style={{background: "url(/bg/background-taisen-kaishi.png) no-repeat left top/cover"}}>
            <div className="title">対戦相手が<br />見つかりました</div>
            <img alt='' className="button-start" src="button/taisen-kaishi.png" onClick={this.startBattle} />
            {/* 戻るボタンはオミット */}
            {/* <Link className="button-back" to="/free-match"><img src="button/modoru.png" /></Link> */}
        </div>
    }

    private startBattle() {
        // バトルビューに遷移
        this.props.history.push(`/battle-view?room=${this.roomId}&type=${BattleType.Free.toString()}&from=${this.from}`);
    }
}
