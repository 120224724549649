import { Card } from '../logics/Symbols';
import { IDialogParams } from '../App';
import { Defines } from './Define';

// シングルトン
class SingletonClass {
	private _showDialogFunction!: (text: string) => void;
	private showVariousDialogFunc: (params: IDialogParams) => void = () => {};
    private closeVariousDialogFunc: () => void = () => {};

	public set showDialogFunction(value: (text: string) => void) {
		this._showDialogFunction = value;
	}
	public get showDialogFunction(): (text: string) => void {
		return this._showDialogFunction;
	}
	private _fromBattleView: boolean = false;
	get fromBattleView(): boolean {
		return this._fromBattleView;
	}
	set fromBattleView(value: boolean) {
		this._fromBattleView = value;
	}

	public updateDialog(showFunc: (params: IDialogParams) => void, closeFunc: () => void) {
        this.showVariousDialogFunc = showFunc;
        this.closeVariousDialogFunc = closeFunc;
    }

	public getCardType(cardType: Card): string {
		switch (cardType) {
			case Card.Yugioh_OCG: return "Yugioh_OCG";
			case Card.Pokemon: return "Pokemon";
			case Card.DuelMasters: return "DuelMasters";
			case Card.MTG: return "MTG";
			case Card.OnePiece: return "OnePiece";
			case Card.UnionArena: return "UnionArena";
			case Card.DragonBall: return "DragonBall";
			default:
				console.error("指定したカードタイプが存在しません");
				return "";
		}
	}

	public getCardFormat(cardType: Card): string {
		switch (cardType) {
			case Card.Pokemon_Standard: return "Pokemon_Standard";
			case Card.Pokemon_Extra: return "Pokemon_Extra";

			case Card.DuelMasters_Advance: return "DuelMasters_Advance";
			case Card.DuelMasters_Original: return "DuelMasters_Original";

			case Card.MTG_Standard: return "MTG_Standard";
			case Card.MTG_Modern: return "MTG_Modern";
			case Card.MTG_Legacy: return "MTG_Legacy";
			case Card.MTG_Pioneer: return "MTG_Pioneer";

			default:
				console.error("指定したフォーマットが存在しません");
				return "";
		}

	}

	public showDialog(text: string) {
		this.showDialogFunction(text);
	}

    public showVariousDialog(params: IDialogParams) {
        this.showVariousDialogFunc(params);
    }

    public closeVariousDialog() {
        this.closeVariousDialogFunc();
    }

	public getOfficialAccountId(): string {
		return Defines.Application.IsDebugMode ? Defines.OfficialAccount.debugId : Defines.OfficialAccount.productId;
	}

	public getSelfAddress(): string {
		return Defines.Application.IsDebugMode ? Defines.SelfAddress.debug : Defines.SelfAddress.product;
	}
}
export const Singleton = new SingletonClass();