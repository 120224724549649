import React,{useEffect} from 'react';
import { Link } from 'react-router-dom';
import { Defines } from '../logics/Define';
import firebase from 'firebase/compat';
import { Singleton } from '../logics/Singleton';
import '../css/main.css';
import '../css/home.css';

export function Home() {

	const updateLogin = () => {
		const userId = firebase.auth().currentUser?.uid.replace("line:", '');
		const userRef = firebase.firestore().collection('user').doc(userId);
		userRef.update({
			loginedAt: firebase.firestore.FieldValue.serverTimestamp(),
		})
	}

	const logout = () => {
		firebase.auth().signOut();
	}

	function isBrowserDefault(): boolean {
		const defaultBrowser = getDefaultBrowser();
		if (defaultBrowser === 'any') { return true }
		const usingBrowser = getUsingBrowser();
		if (defaultBrowser.indexOf(usingBrowser) !== -1) {
			return true;
		} else {
			return false;
		}
	}
	function getDefaultBrowser(): string {
		const usingMachine = getUsingMachine();
		if (usingMachine === 'iPhone' || usingMachine === 'iPad') {
			return 'Safari または Google Chrome';
		} else if (usingMachine === 'android mobile' || usingMachine === 'android tablet') {
			return 'Google Chrome';
		} else {
			return 'any';
		}
	}
	function getUsingBrowser(): string {
		const userAgent = window.navigator.userAgent.toLowerCase();
		if (userAgent.indexOf('msie') !== -1 || userAgent.indexOf('trident') !== -1) {
			return 'Internet Explorer';
		} else if (userAgent.indexOf('edg') !== -1) {
			return 'Edge';
		} else if (userAgent.indexOf('opera') !== -1 || userAgent.indexOf('opr') !== -1) {
			return 'Opera';
		}else if(userAgent.indexOf('line') !== -1){
			return 'Line';
		} else if (userAgent.indexOf('chrome') !== -1 || userAgent.indexOf('crios') !== -1) {
			return 'Google Chrome';
		} else if (userAgent.indexOf('firefox') !== -1 || userAgent.indexOf('fxios') !== -1) {
			return 'FireFox';
		} else if (userAgent.indexOf('safari') !== -1) {
			return 'Safari';
		} else {
			return 'others';
		}
	}
	function getUsingMachine(): string {
		const userAgent = window.navigator.userAgent.toLowerCase();
		if (userAgent.indexOf('ipad') !== -1) {
			return 'iPad';
		} else if (userAgent.indexOf('iphone') !== -1) {
			return 'iPhone';
		} else if (userAgent.indexOf('android') !== -1) {
			if (userAgent.indexOf('mobile') !== -1) {
				return 'android mobile';
			} else {
				return 'android tablet';
			}
		} else {
			return 'PC';
		}
	}

	useEffect(()=>{
		if(!isBrowserDefault()){
			Singleton.showDialog('デフォルトブラウザ以外での動作は保証しておりません。' + getDefaultBrowser() + 'でご利用ください。')
			}
	}, []);

	return (
		<div className="home">
			<img alt='' className="logo" src="logo/REMOTAKU_LOGO.png" />
			{Defines.Application.IsDebugMode && <div className="beta">Dev</div>}
			<Link to="/nickname" onClick={updateLogin}><img alt='' className="play" src="button/PLAY.png" /></Link>
			<button onClick={logout}><img alt='' className="logout" src="button/logout.png" /></button>
			<Link to="/reset-password"><img alt='' className="password-henkou button-link" src="button/toroku_johou_henko.png" /></Link>
			<div><a href="./howtouse.html"><img alt='' className="how-play" src="button/goriyou.png" /></a></div>
			<ul className="werning-list">
				<li>
					<img alt='' className="wernning" src="button/exclamation.png" />
					<p className="text">Android端末のうちGalaxyをお使いの方は<br />ブラウザはFirefoxをお使いください</p>
				</li>
			</ul>
			<div className="footer">
				<ul className="navi">
					<li className="margin-right"><a className="margin-right" href="./howtouse.html">使い方</a>|</li>
					<li className="margin-right"><a className="margin-right" href="./privacy.html">プライバシーポリシー</a>|</li>
					{/* <li className="margin-right"><a className="margin-right" href="">利用規約</a>|</li> */}
					<li className="margin-right"><a className="margin-right" href="./faq.html">FAQ</a>|</li>
					<li><a href="https://lin.ee/wXY9hSy">お問い合わせ</a></li>
				</ul>
				<img alt='' className="copyright" src="logo/footer_toyger-logo.png" />
			</div>
		</div>
	)
}