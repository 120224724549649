import React, { useEffect } from 'react';
import { useState } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { Defines } from './logics/Define';
import '../src/css/main.css';
import { Login } from './components/Login';
import { Home } from './components/Home';
import { Nickname } from './components/Nickname';
import { GameSelect } from './components/GameSelect';
import { BattleSelect } from './components/BattleSelect';
import { FriendMatch } from './components/FriendMatch';
import { FreeMatch } from './components/FreeMatch';
import { BattleView2 } from './components/BattleView2';
import { BattleWait } from './components/BattleWait';
import { BattleReady } from './components/BattleReady';
import { FormatSelect } from './components/FormatSelect';
import { EventSelect } from './components/EventSelect';
import { Maintainance } from './components/Maintainance';
import { SettingsDialog } from './components/Dialog';
import { Singleton } from './logics/Singleton';
import { Auth } from './components/Auth'
import { Register } from './components/Register';
import { Reset } from './components/Reset';
import { LineNotifyCallback } from './components/LineNotifyCallback';
//import {Provider, useDispatch, useProviderContext} from './components/Provider';
import { AuthProvider } from './components/AuthContext';
import PrivateRoute from './components/PrivateRouter';
import PublicRoute from './components/PublicRoute';
import FreeRoute from './components/FreeRouter';

function App() {
	const [textMessage, setTextMessage] = useState('');
	const [isOpen, setIsOpen] = useState(false);
	const [dialogState, SetDialogState] = useState<IDialogParams | null>(null);

	useEffect(() => {
		Singleton.updateDialog(
			// showFunc
			params => {
				SetDialogState(params);
			},
			// closeFunc
			() => {
				SetDialogState(null);
			}
		)
	})

	Singleton.showDialogFunction = (text: string) => {
		setTextMessage(text);
		setIsOpen(true);
	}

	return (
		<AuthProvider>
			<BrowserRouter>
				<div>
					<Switch>
						{Defines.Application.IsMaintainanceMode && <Route path="*" component={Maintainance} />}
						<PrivateRoute exact path="/" component={Home} />
                        <PublicRoute path="/auth" component={Auth} />
                        <PublicRoute path="/login" component={Login} />
						<PrivateRoute exact path="/reset-password" component={Reset} />
                        <PrivateRoute exact path="/register" component={Register} />
						<PrivateRoute exact path="/nickname" component={Nickname} />
						<PrivateRoute exact path="/game-select" component={GameSelect} />
						<PrivateRoute exact path="/battle-select" component={BattleSelect} />
						<PrivateRoute exact path="/friend-match" component={FriendMatch} />
						<PrivateRoute exact path="/free-match" component={FreeMatch} />
						<PrivateRoute exact path="/format-select" component={FormatSelect} />
						<PrivateRoute exact path="/event-select" component={EventSelect} />
						<FreeRoute path="/battle-view" component={BattleView2} />
						<PrivateRoute exact path="/battle-wait" component={BattleWait} />
						<PrivateRoute exact path="/battle-ready" component={BattleReady} />
						<PrivateRoute exact path="/line-notify-callback" component={LineNotifyCallback} />
					</Switch>
					<SettingsDialog
						textMessage={textMessage}
						isOpen={isOpen}
						onClose={() => setIsOpen(false)}
					/>
					{dialogState && dialogState.body}
				</div>
			</BrowserRouter>
		</AuthProvider>
	);
}

export interface IDialogParams {
	body: React.ReactElement<Element>;
}

export default App;
