import React from 'react';
import '../css/main.css';
import '../css/home.css';
import '../css/maintainance.css';

export function Maintainance() {
    return (
        <div className="home maintainance" style={{background: "url(/bg/background_TOP.png) no-repeat left top/cover"}}>
            <img alt='' className="logo" src="logo/REMOTAKU_LOGO.png" />
            <div className="notice">ただいまメンテナンスのため一時サービスを停止しております。<br />サービス再開まで今しばらくお待ちください。</div>
            <div className="footer">
                <ul className="navi">
                    <li className="margin-right"><a className="margin-right" href="./howtouse.html">使い方</a>|</li>
                    <li className="margin-right"><a className="margin-right" href="./privacy.html">プライバシーポリシー</a>|</li>
                    {/* <li className="margin-right"><a className="margin-right" href="">利用規約</a>|</li> */}
                    <li className="margin-right"><a className="margin-right" href="./faq.html">FAQ</a>|</li>
                    <li><a href="https://lin.ee/wXY9hSy">お問い合わせ</a></li>
                </ul>
                <img alt='' className="copyright" src="logo/footer_toyger-logo.png" />
            </div>
        </div>
    )
}
