import * as React from "react";
import { RouteComponentProps } from "react-router-dom";
import { Utility } from "../logics/Utility";
import firebase from 'firebase/compat';

export class LineNotifyCallback extends React.Component<RouteComponentProps, {}> {
    public constructor(props: RouteComponentProps) {
        super(props);
        this.state = {
        };
    }

    public async componentDidMount() {
        const code = Utility.getQueryParams("code", window.location.search);
        const state = Utility.getQueryParams("state", window.location.search);

        if (state && state !== Utility.getHash(firebase.auth().currentUser!.uid).toString()) {
            // 不正なstateなのでHomeへ飛ばす
            this.props.history.push("./");
            console.log("不正なstateです")
        }
        if (code) {
            // アクセストークンをユーザー情報に登録する
            console.log("アクセストークンを取得します")
            const functions = firebase.app().functions("asia-northeast1");
            const call = functions.httpsCallable("getLineNotifyToken");
            const res = await call({code: code});
            console.log(JSON.stringify(res));
            const token = res.data.access_token;
            console.log("アクセストークンを登録します");
            const userId = firebase.auth().currentUser?.uid.replace("line:", '');
            const userRef = firebase.firestore().collection('user').doc(userId);
            userRef.set({
                lineNotifyToken: token,
            }, {merge: true})
            .then(() => this.props.history.push("./"))
            .catch(e => console.error(e));
        } else {
            console.log("codeがありません")
            this.props.history.push("./");
        }
    }

    public render() {
        return <div>登録処理中です</div>
    }
}