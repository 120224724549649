import React from 'react';
import { Route } from 'react-router-dom';

const FreeRoute:React.FC<{
    component: React.FC | any;
    path: string;
}> = props => {
  return (
    <Route
      render={(routeProps) => {
        return <Route path={props.path} component={props.component} />;
      }}
    />
  );
};

export default FreeRoute;