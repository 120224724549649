import * as React from 'react';
import ReactModal from 'react-modal';

import '../css/dialog.css';

interface Props {
	/** ダイアログ本文に表示するメッセージ */
	textMessage: string;
	/** このダイアログを表示するなら true */
	isOpen: boolean;
	/** このダイアログを閉じるときのコールバック */
	onClose?: () => void;
}
interface State {
	status: string;
}

export class SettingsDialog extends React.Component<Props, State> {
	constructor(props: Props) {
		super(props);
		ReactModal.setAppElement('body');
	}
	okButtonMessage = 'OK';
	cancelButtonMessage = 'キャンセル';
	public render(): React.ReactNode {
		return <div>
			<ReactModal
				isOpen={this.props.isOpen}
				onRequestClose={this.handleClose}
				className='box'
				overlayClassName="Overlay"
				contentLabel="Dialog"
			>
				<span className='textMessage'>{this.props.textMessage}</span>
				<button className='cancelBottun' onClick={this.handleClose}> {this.cancelButtonMessage} </button>
				<button className='okBottun' onClick={this.handleOkSubmit}> {this.okButtonMessage} </button>
			</ReactModal>
		</div>;
	}

	// ダイアログ領域外のクリックや、ESCキーを押したときに呼び出され
	private handleClose = () => {
		// 親コンポーネントにダイアログを閉じてもらうためのコールバック通知
		this.props.onClose?.();
	}


	private handleOkSubmit = () => {
		this.setState({ status: 'OK' });
		this.props.onClose?.();
	}


}