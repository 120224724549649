import React from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { User } from '../logics/User';
import { Utility } from '../logics/Utility';
import { Defines } from '../logics/Define';
import{Singleton}from'../logics/Singleton';
import '../css/main.css';
import '../css/nickname.css';

interface Props extends RouteComponentProps {
}
interface State {
    name: string;
}
export class Nickname extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            name: ""
        }

        this.handleInput = this.handleInput.bind(this);
        this.onClickKettei = this.onClickKettei.bind(this);
    }

    public componentDidMount() {
        const nickname = Utility.getLocalStorage(Defines.LocalStorageKey.nickname);
        if(nickname){
            this.setState({name: nickname});
        }
    }

    public render() {
        return (
            <div className="nickname" style={{background: "url(/bg/background_2.png) no-repeat left top/cover"}}>
                <div className="title">ニックネームの入力</div>
                <div><img alt='' className="sankaku" src="button/sankaku.png" /></div>
                <input type="text" value={this.state.name} onChange={this.handleInput} maxLength={12} />
                <img alt='' className="button-normal" src="button/kettei.png" onClick={this.onClickKettei} />
                <Link className="button-back" to="/"><img alt='' src="button/modoru.png" /></Link>
            </div>
        )
    }

    private handleInput(e: React.ChangeEvent<HTMLInputElement>) {
        this.setState({name: e.target.value});
    }

    private onClickKettei(e: React.MouseEvent<HTMLImageElement>) {
        if(this.state.name.length <= 0) {
            Singleton.showDialog("1文字以上入力してください");
            return;
        }
        User.nickName = this.state.name;
        Utility.setLocalStorage(Defines.LocalStorageKey.nickname, this.state.name);
        this.props.history.push("/battle-select");
    }
}
