import React from 'react';
import { Link } from 'react-router-dom';

import { User } from '../logics/User'

export function GameSelect() {
    return (
        <div>
            <h1>カードゲームの選択</h1>
            <button><Link to="/battle-select">遊戯王</Link></button>
            <button><Link to="/battle-select">ポケモン</Link></button>
            <button><Link to="/nickname">戻る</Link></button>
            <div className="space" />
            <div>ニックネーム：{User.nickName}</div>
        </div>
    )
}